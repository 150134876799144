import React from 'react';
import { useNavigate } from 'react-router-dom';

const CategoryCredit = ({ categoryName, imageUrl,path }) => {
    const navigate=useNavigate();
    return (
        <div className="credit-container" onClick={()=>{navigate('/'+path)}}>
        <div className="category-name">
            {categoryName}
        </div>
        <div className="category-image">
            <img src={imageUrl} alt={categoryName} />
        </div>
    </div>
    );
}

export default CategoryCredit;
