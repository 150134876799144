import React, { useContext, useEffect, useState } from 'react';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import DatePicker from '../../Components/DatePicker/DatePicker';
import axios from 'axios';
import { ApiURL } from '../../App';
import { UserInfokh } from '../../Contexts/UserContext';
import { NumericFormat } from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';
import NotFound from '../../Components/NotFound/NotFound';
import AddButton from '../../Components/AddButton/AddButton';
import { StaticInfo } from '../../Contexts/StaticDataContext';
import { Outlet } from 'react-router-dom';
import FuelComponent from '../../Components/Fuel Components/FuelComponent';

const AllFuel = () => {

  //#region variables and states 

    const userInfo_Now =useContext(UserInfokh);
    const [Alldata, setAlldata] = useState([]);
    const [loading,setloadnig]=useState(true);
    const [dataAsdate, setdataAsdate] = useState([]);
    const [SumAll, setSumAll] = useState(0);
    
    const [value, setValue] = useState(new Date());
    const [SearchFlage,setSearchFlage]=useState(false);
    
    //this for search
    const [formDataSearch, setformDataSearch] = useState({
      repositoryMaterialId: 0,
      supplierName: '',
      Fromdate: '',
      ToDate: '',
      Allresults: false,
    });
    //#endregion
   
    useEffect(() => {
        const fetchData = async () => {
            setloadnig(true);
          
          axios.get(ApiURL+'Fuel/GetAllFuels',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              setAlldata(data.data);
              setloadnig(false);
              
            })
            .catch((err)=>{
                setloadnig(false);
            //   setِ_ِAlert_msg(err);
            //   setِAlert(true); 
                       
              })
        
        };
    
        fetchData();
      }, []);

      useEffect(() => {
       
        let data_temp = [];
        let data_temp_forSearch = [];
        setSumAll(0);
        let sum_=0;
       
        if( formDataSearch.supplierName!==''||
          formDataSearch.Fromdate!==''||formDataSearch.ToDate!==''|| formDataSearch.Allresults)
          {
            setSearchFlage(true);
            data_temp_forSearch = Alldata.concat();
            if(!formDataSearch.Allresults)
              {
            if(formDataSearch.supplierName!=='')
              {
                data_temp_forSearch=data_temp_forSearch.filter(item => item.sourceName === formDataSearch.supplierName)
              }
              if (formDataSearch.Fromdate !== '') {
                data_temp_forSearch = data_temp_forSearch.filter(item => new Date(item.date).getTime() > new Date(formDataSearch.Fromdate).getTime());
              }
              
              if (formDataSearch.ToDate !== '') {
                data_temp_forSearch = data_temp_forSearch.filter(item => ((new Date(item.date)).getTime()) < ((new Date(formDataSearch.ToDate)).getTime()));
               
              }
            }
              data_temp_forSearch.forEach((item) => {
            
                  const itemCopy = { ...item }; // Create a copy of the item object
                
                  if(item.direction===0)
                    {
                      itemCopy.totalPrice=-item.totalPrice;
                    }              
                  sum_ +=itemCopy.totalPrice;
                  data_temp.push(itemCopy);
                  
              });
          }
          else
          {
            setSearchFlage(false);
            Alldata.forEach((item) => {
            
                  // Convert item.date and value to Date objects if they are not already
                  const itemDate = new Date(item.date);          
                  const valueDate_previous =new Date(value.getFullYear(),value.getMonth(),1);
                  const valueDate_end = new Date(value.getFullYear(),value.getMonth()+1,1);
                  
                  const itemDate2=itemDate.getTime();
                  const valueDate2=valueDate_end.getTime();
                  const valueDate_previous2=valueDate_previous.getTime();
                  
                  // Compare the dates using getTime()
                  if (valueDate_previous2 <= itemDate2)
                    {if(itemDate2 <= valueDate2) 
                    {
                      
                      const itemCopy = { ...item }; // Create a copy of the item object
                    
                      if(item.direction===0)
                        {
                          itemCopy.totalPrice=-item.totalPrice;
                        }              
                      sum_ +=itemCopy.totalPrice;
                      data_temp.push(itemCopy);
                    }
                  
                  }
                
            });
          }
        setSumAll(sum_.toFixed(2));
        setdataAsdate(data_temp);
      }, [value, Alldata,formDataSearch]);
      
    return (
    <>
        <Outlet context={{ setAlldata,formDataSearch, setformDataSearch,SearchMaterialActivator:false  }}/>
         <UserHeader title='المحروقات'  Active={5}/>
        <DatePicker SearchFlage={SearchFlage} value={value} setValue={setValue}/>
         {!loading && dataAsdate.length===0 && <NotFound />}
         {dataAsdate.length>0 && <div className='GetAll-pages-kh'>
            <div className='MaterialPurchases-Sum' ><h6><b>المجموع: </b><span style={SumAll>0?{color:'red'}:{color:'green'}} ><NumericFormat value={SumAll} displayType={'text'} thousandSeparator={true} />  ل.س</span></h6></div>
         
           {dataAsdate.map((item)=>
                <FuelComponent key={item.id} props={item} />                
            )}
          
        </div>
        
        }
        {loading && <Skeleton_kh />}   
       <AddButton />
    </>
        
    );
}

export default AllFuel;
