import Select from 'react-select';
import React, { useContext, useEffect, useState } from 'react';
import { UserInfokh } from '../../Contexts/UserContext';
import { ApiURL } from '../../App';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import KhAlert from '../../Components/Alert/KhAlert';
import { TailSpin } from 'react-loader-spinner';
import { useOutletContext } from 'react-router-dom';

const GenerateExpenseBill = () => {

    const [supplierOfFarmsNameActive_,setsupplierOfFarmsNameActive_]=useState(true);

    const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
  
    const [Matirialoptions, setMatirialoptions] = useState([]);
    const [BuyerNamesoptions, setBuyerNamesoptions] = useState([]);
    const [SupplierofFarmsNamesoptions, setSupplierofFarmsNamesoptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('daily');
    const animatedComponents = makeAnimated();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption_source, setselectedOption_source] = useState(null); 
    const [selectedOption_Mowarid,setselectedOption_Mowarid] = useState(null); 


    useEffect(() => {

        const fetchData = async () => {
           
                    
          axios.get(ApiURL +'RepositoryMaterials/GetAll',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              const Material_holder=[];
              data.data.materials.map((item)=>{
                Material_holder.push({
                  value:item.id,
                  label:item.name,
                  defaultPrice:item.defaultPrice})
                
              });
              setMatirialoptions(Material_holder);
              
              const buyers_holder=[];
              data.data.suppliers.map((item)=>{
                  buyers_holder.push({ value:item.id,
                  label:item.supplierName})
                
              })
              
              setBuyerNamesoptions(buyers_holder);

              const SupplierOfFarms_holder=[];
              data.data.soFarms.map((item)=>{
                SupplierOfFarms_holder.push({ value:item.id,
                  label:item.supplierName})            
              })
              
              setSupplierofFarmsNamesoptions(SupplierOfFarms_holder);
              
            })
            .catch((err)=>{
              SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
              })
        
        };
    
        fetchData();
      }, []);

    
      const [formData, setFormData] = useState({
          dealerId: 0,
          dealerName:'',
          supplierId:0,
          supplierName:'',
          coolingRooms: false,
          refrigerator: false,
          externalEnvoices: false,
          daily: true,
          tabali: false,
          plastic: false,
          karasta: false,
          fuel: false,
          cars: false,
          employees:false,
          startDate: null,
          endDate: null
          
      });

      const handleChange = (name, value,label) => {
        
        if(name==='daily')
            {
                setSelectedOption(value);
                setsupplierOfFarmsNameActive_(true);
                setFormData({
                    ...formData,
                    'refrigerator': false,
                    'coolingRooms': false,
                    'externalEnvoices': false,
                    "daily": true,
                    "tabali": false,
                    "plastic": false,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else if(name==='tabali')
            {
                setSelectedOption(value);
                setsupplierOfFarmsNameActive_(false);
                setFormData({
                    ...formData,
                    'refrigerator': false,
                    'coolingRooms': false,
                    'externalEnvoices': false,
                    "daily": false,
                    "tabali": true,
                    "plastic": false,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else if(name==='plastic')
            {
                setSelectedOption(value);
                setsupplierOfFarmsNameActive_(false);
                setFormData({
                    ...formData,
                    'refrigerator': false,
                    'coolingRooms': false,
                    'externalEnvoices': false,
                    "daily": false,
                    "tabali": false,
                    "plastic": true,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else if(name==='karasta')
                {
                    setSelectedOption(value);
                    setsupplierOfFarmsNameActive_(false);
                    setFormData({
                        ...formData,
                        'refrigerator': false,
                        'coolingRooms': false,
                        'externalEnvoices': false,
                        "daily": false,
                        "tabali": false,
                        "plastic": false,
                        "karasta": true,
                        "fuel": false,
                        "cars": false
                      });
                }
                else if(name==='fuel')
                    {
                        setSelectedOption(value);
                        setsupplierOfFarmsNameActive_(false);
                        setFormData({
                            ...formData,
                            'refrigerator': false,
                            'coolingRooms': false,
                            'externalEnvoices': false,
                            "daily": false,
                            "tabali": false,
                            "plastic": false,
                            "karasta": false,
                            "fuel": true,
                            "cars": false
                          });
                    }
                    else if(name==='cars')
                        {
                            setSelectedOption(value);
                            setsupplierOfFarmsNameActive_(false);
                            setFormData({
                                ...formData,
                                'refrigerator': false,
                                'coolingRooms': false,
                                'externalEnvoices': false,
                                "daily": false,
                                "tabali": false,
                                "plastic": false,
                                "karasta": false,
                                "fuel": false,
                                "cars": true
                              });
                        }

            else
            {
              
                if(value==="")
                    value=null;

                if(name==='dealerId')
                  {
                   setFormData({
                     ...formData,
                     [name]: value,
                     dealerName: label
                   });
                   setselectedOption_source({value,label})
                 }
                 else if(name==='supplierId')
                  {
                    setFormData({
                      ...formData,
                      [name]: value,
                      supplierName: label
                    });
                   setselectedOption_Mowarid({value,label})
                  }
                 else
                 {
                 setFormData({
                     ...formData,
                     [name]: value
                   });
                 }
                
               
            }

       
      };

      const handleSubmit = async (e) => {
        Setflag(true);
        if(!((formData.dealerId<1 && selectedOption!=='daily')||
              (selectedOption==='daily' && formData.dealerId<1 && formData.supplierId<1) ))
        {
            console.log(formData);
          try {
            setIsLoading(true); // Start loading
          const res = await axios.post(ApiURL+'Billing/GeneratePdf', formData, {
            headers: {
              'Accept': 'text/plain',
              'Content-Type': 'application/json',
               'Authorization': `bearer ${userInfo_Now.auth.token}`
            },
            responseType: 'blob', 
          });
          // Step 3: Create a Blob from the response and a URL to open or display it
    const blob = new Blob([res.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

            // Option 1: Open PDF in a new tab
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'billing.pdf'); // or any other name you want
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
                  
          
        } catch (error) {      
          SetAlert({Activate:true,msg:error+'تأكد من الاتصال بالانترنت'});
         
        }
        finally {
            setIsLoading(false); // End loading
        }
        }
      };

    return (
        <>
            <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
       <div className='parent-Dmaterial'>
       <div className='child-Dmaterial'>
       
       {!isLoading && <> <div style={{margin:'10px',textAlign:'center'}}>
             <h3>  فواتير المصاريف </h3></div>  
            <div className="mb-3">
                  <label className="form-label">
                  المصدر
                  </label>
                  <Select
                      id='Select_dealerId_'
                      components={animatedComponents}
                      options={BuyerNamesoptions}
                      value={selectedOption_source}
                      onChange={(selectedOption) => handleChange('dealerId', selectedOption===null?0:selectedOption.value,selectedOption===null?'':selectedOption.label)}
                      isClearable
                      isSearchable
                      placeholder="اختر مصدر ..."
                    />
                 {(selectedOption==='daily' && formData.dealerId<1 && formData.supplierId<1) &&flag&&  (<p className="validationReg"> *الرجاء اختيار مزارع أو مورد </p>) 
                    || (selectedOption!=='daily' && formData.dealerId<1) &&  <p className="validationReg"> *الرجاء اختيار مصدر </p>}
              </div> 
              {supplierOfFarmsNameActive_ &&<div className="mb-3">
                            <label className="form-label">
                            المورد:
                            </label>
                            <Select
                                id='Select_supplierName_'
                                components={animatedComponents}
                                options={SupplierofFarmsNamesoptions}
                                value={selectedOption_Mowarid}
                                onChange={(selected)=> handleChange('supplierId',selected===null?0:selected.value,selected===null?'':selected.label)} 
                                isClearable
                                isSearchable
                                placeholder="اختر مورد..."                    
                            />              
                        </div> }

              <div className="mb-3">
                <label className="form-label">
                  من تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  defaultValue={formData.startDate}
                  onChange={(e)=>{handleChange('startDate',e.target.value)}}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">
                  حتى تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  defaultValue={formData.endDate}
                  onChange={(e)=>{handleChange('endDate',e.target.value)}}
                />
            </div>
            
            <div className="mb-3">
                <div>
                
                <input
                    className='radio-kh'
                    type="radio"
                    name="daily"
                    value="daily"
                    id="daily"
                    checked={selectedOption === 'daily'}
                    onChange={(e)=>{handleChange('daily',e.target.value)}}
                />
                <label htmlFor='daily' className="form-label">
                الرزق اليومي 
                </label>
                </div>
                <div>
               
                <input
                    className='radio-kh'
                    type="radio"
                    name="tabali"
                    value="tabali"
                    id="tabali"
                    checked={selectedOption === 'tabali'}
                    onChange={(e)=>{handleChange('tabali',e.target.value)}}
                />
                 <label htmlFor='tabali' className="form-label">
               طبالي
                </label>
                </div>
                <div>
                
                <input
                    className='radio-kh'
                    type="radio"
                    name="plastic"
                    value="plastic"
                    id="plastic"
                    checked={selectedOption === 'plastic'}
                    onChange={(e)=>{handleChange('plastic',e.target.value)}}
                />
                <label htmlFor='plastic' className="form-label">
                بلاستيك
                </label>
                </div>

                <div>
               
                <input
                    className='radio-kh'
                    type="radio"
                    name="karasta"
                    value="karasta"
                    id="karasta"
                    checked={selectedOption === 'karasta'}
                    onChange={(e)=>{handleChange('karasta',e.target.value)}}
                />
                 <label htmlFor='karasta' className="form-label">
               كرستا
                </label>
                </div>

                <div>
               
                <input
                    className='radio-kh'
                    type="radio"
                    name="fuel"
                    value="fuel"
                    id="fuel"
                    checked={selectedOption === 'fuel'}
                    onChange={(e)=>{handleChange('fuel',e.target.value)}}
                />
                 <label htmlFor='fuel' className="form-label">
               محروقات
                </label>
                </div>

                <div>
               
                <input
                    className='radio-kh'
                    type="radio"
                    name="cars"
                    value="cars"
                    id="cars"
                    checked={selectedOption === 'cars'}
                    onChange={(e)=>{handleChange('cars',e.target.value)}}
                />
                 <label htmlFor='cars' className="form-label">
               أجار سيارات
                </label>
                </div>
               
                </div>
                <button className='btn-Add' onClick={()=>{handleSubmit()}} >طباعة</button>
                </>}

                {isLoading && (
            <div style={{textAlign:'center'}}>
                <TailSpin
                    wrapperClass={'loading-kh-new'}
                    height="400"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="loading"
                />
            </div>
        )}
                </div> 
        
       
        </div>
      
        
        </>
    );
}

export default GenerateExpenseBill;
