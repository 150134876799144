import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const ExternalEnvoicesCom = (props) => {
    
    const navigate=useNavigate();
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };
    
    return (
        <div className='Transaction-kh Incoming-kh'>
          
             <div className="parent-kh" onClick={()=>{navigate('DetailsExternalEnvoices?Id='+props.props.id)}}>
                <div> <h5><span style={props.props.salesPriceOfAll<0?{color:'red'}:{color:'green'}} ><NumericFormat decimalScale={1} value={props.props.salesPriceOfAll} displayType={'text'} thousandSeparator={true} />  ل.س</span> </h5></div>
                <div> <h4>{props.props.materialName} | {props.props.buyerName} </h4></div>
                <div> <h5>{formatDate(props.props.date)}</h5></div>
                <div> <h5> <NumericFormat decimalScale={1} value={props.props.weight} displayType={'text'} thousandSeparator={true} /> كغ | <span style={{color:'green'}} ><NumericFormat decimalScale={1} value={props.props.salesPriceOfUnit} displayType={'text'} thousandSeparator={true} />  ل.س</span></h5></div>
            </div>
            
           
        </div>
    );
}


export default ExternalEnvoicesCom;
