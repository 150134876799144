import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FloatBtn = () => {    
    
    const [classbtn_plus,setclassbtn_plus]=useState('Float-button button-kh')
const [classbtn_min,setclassbtn_min]=useState('Float-button button-kh')
const [classbtn_main,setclassbtn_main]=useState('Float-button')
const [ClassActivate,setClassActivate]=useState(false)
const navigate=useNavigate();
function MainBtn_hundler()
{
    if(classbtn_plus==='Float-button button-kh')
        {
            setclassbtn_plus('Float-button button-plus')
            setclassbtn_min('Float-button button-min')
            setclassbtn_main('Float-button Float-button-rot')
            setClassActivate(true)
        }
    else
        {
            setclassbtn_plus('Float-button button-kh');
            setclassbtn_min('Float-button button-kh');
            setclassbtn_main('Float-button')
            setClassActivate(false)
        }
       
}
   
    return (
        <>           
            <button className={classbtn_main} onClick={()=>MainBtn_hundler()}>+</button>
            <button className={classbtn_min} onClick={()=>{MainBtn_hundler(); navigate('/AddBoxesExiting');}}>-</button><div className={ClassActivate?'button-min-txt':'button-txt'}> خارج</div> 
            <button className={classbtn_plus}  onClick={()=>{MainBtn_hundler(); navigate('/AddBoxesEntering');}}>+</button><div className={ClassActivate?'button-plus-txt':'button-txt'}> داخل </div> 
        </>
    );
}

export default FloatBtn;

