
import { useContext, useState } from 'react';
import KhAlert from '../../../Components/Alert/KhAlert';
import { UserInfokh } from '../../../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiURL } from '../../../App';

const AddEmployee = () => {

    
    const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
    const navigate =useNavigate();
    const [formData, setFormData] = useState({
        workshopName: '',
        normHWageM: 0,
        normHWageG:0,
        additionalWorkingHourWage:0,
        date: '',
        notes: ''
      });
   
      const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value
        });        
      };

      const handleSubmit = async (e) => {
        Setflag(true);
        if(!(formData.date.length<1 ||formData.workshopName.length<1||formData.normHWageM.length<1
          ||formData.normHWageG.length<1||formData.additionalWorkingHourWage.length<1
        ))
        {
          try {
          const res = await axios.post(ApiURL+'Employee/AddNewEmployee', formData, {
            headers: {
              'Accept': 'text/plain',
              'Content-Type': 'application/json',
               'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
           
          });
          navigate('/AllEmployees');
          navigate(0)
          
        } catch (error) {      
          SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
         
        }
        }
      };

      //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
      // Prevent the input value change
      e.target.blur()
    
      // Prevent the page/container scrolling
      e.stopPropagation()
    
      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus()
      }, 0)
    }
    //#endregion
    return (
        <>
         <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <div className='parent-Dmaterial'>
                <div className='child-Dmaterial'>
                <div className="mb-3"> 
              <label className="form-label">
              اسم الورشة 
              </label>
              <input
                className="form-control"
                type='text'
                name="workshopName"
                value={formData.workshopName}
                onChange={(e)=>{handleChange('workshopName',e.target.value)}}
							/>
              {formData.workshopName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء إدخال اسم الورشة  </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
              أجار الساعة للشباب 
              </label>
              <input
                className="form-control"
                type="number"
                name="normHWageM"
                value={formData.normHWageM}
                onChange={(e)=>{handleChange('normHWageM',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
               {formData.normHWageM.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
               </div>
           
            <div className="mb-3"> 
              <label className="form-label">
              أجار الساعة للبنات
              </label>
              <input
                className="form-control"
                type="number"
                name="normHWageG"
                value={formData.normHWageG}
                onChange={(e)=>{handleChange('normHWageG',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
             {formData.normHWageG.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
                
            <div className="mb-3"> 
              <label className="form-label">
             أجار ساعة العمل الإضافي
              </label>
              <input
                className="form-control"
                type="number"
                name="additionalWorkingHourWage"
                value={formData.additionalWorkingHourWage}
                onChange={(e)=>{handleChange('additionalWorkingHourWage',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
             {formData.additionalWorkingHourWage.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
           
            <div className="mb-3">
              <label className="form-label">
                التاريخ
              </label>
              <input
                className="form-control"
                type="date"
                name="date"
                value={formData.date}
                onChange={(e)=>{handleChange('date',e.target.value)}}
              />
               {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
            </div>
            <div className="mb-3">
                <label className="form-label">
                ملاحظات
                </label>
                <textarea 
                type='text'
                className="form-control"
                name="notes" 
                value={formData.notes} 
                onChange={(e)=>{handleChange('notes',e.target.value)}}
                placeholder={"إضافة ملاحظات..."}               
                />
              
            </div>
       
        <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
                </div>
            </div>
            
        </>
    );
}

export default AddEmployee;
