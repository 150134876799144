import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const Debts = (props) => {

    const navigate=useNavigate();

    return (
        <div className='Transaction-kh' style={props.props.remainder<0?{borderLeftColor:'red'}:{borderLeftColor:'green'}}>
          
        <div className="parent-kh" onClick={()=>{navigate('/debts/DetDebts?Id='+props.props.id)}}>
           <div> <h5><strong>الدفعات الكلية: </strong><span style={props.props.totalPayments<0?{color:'red'}:{color:'green'}} ><NumericFormat value={props.props.totalPayments} displayType={'text'} thousandSeparator={true} />  ل.س</span> </h5></div>
           <div> <h4><strong>اسم التاجر/المصدر: </strong>{props.props.supplierName} </h4></div>
           <div> <h5><strong>المتبقي: </strong><span style={props.props.remainder<0?{color:'red'}:{color:'green'}} ><NumericFormat value={props.props.remainder} displayType={'text'} thousandSeparator={true} />  ل.س</span> </h5></div>
           <div> <h5> المبلغ الكلي: <span style={props.props.totalAmount<0?{color:'red'}:{color:'green'}} ><NumericFormat value={props.props.totalAmount} displayType={'text'} thousandSeparator={true} />  ل.س</span> </h5></div>
       </div>
       
      
   </div>
    );
}

export default Debts;
