import React from 'react';
import DataListAllBoxes from './DataListAllBoxes';
import { useNavigate } from 'react-router-dom';



const BoxesWidrawnByFar = ({title,data}) => {
    const navigate=useNavigate();
    return (
        <div className='CardWithPie-Parent' style={{ marginBottom: '5px'}}>
            <div><h3>{title}</h3></div>
            <div className='table-Boxes-parent'>
            <table className="custom-table-Boxes">
                <thead>
                    <tr>
                    <th>لم يتم إعادتها</th>
                    <th>تم إعادتها</th>
                    <th>مسحوبة</th>
                    <th>الاسم</th>
                    </tr>
            </thead>
            <tbody>
                {data.bottomOfThePage.map((row, index) => (
                  
                    
                <tr key={index} onClick={()=>{navigate('/BoxesOfUser?id='+row.userId+'&name='+row.userName)}}>
                    <td>
                    <div style={{color:'red'}}> {row.remainder} </div>
                    </td>
                    <td>
                    <div style={{color:'green'}}>  {row.totalIn} </div>
                    </td>
                    <td>
                    <div style={{color:'red'}}>  {row.totalOut} </div>
                    </td>
                    <td>
                    <div>  {row.userName} </div>
                    </td>

                </tr>
              
                 ))} 
            </tbody>
             </table>
               
            </div>
        </div>
    );
}

export default BoxesWidrawnByFar;