import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const BarChartKh = ({ data, color }) => {
    const getPath = (x, y, width, height) => {
        return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
      };
    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
      
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
      };
    return (
        <>
        <ResponsiveContainer width="100%" height={180}>
            <BarChart data={data}
            margin={{
                top: 20,
                right: 1,
                bottom: 20,
                left: 1,
              }}>
            
                <XAxis dataKey="date" />                
                <Tooltip />
                <Bar barSize={30} dataKey="value" fill={color} >
                    <LabelList dataKey="value" position="top" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
          
        </>
    );
}

export default BarChartKh;
