import Select from 'react-select';
import React, { useContext, useEffect, useState } from 'react';
import { UserInfokh } from '../../Contexts/UserContext';
import { ApiURL } from '../../App';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import KhAlert from '../../Components/Alert/KhAlert';
import { TailSpin } from 'react-loader-spinner';

const StatementOfAccount = () => {

    const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
  
    const [Matirialoptions, setMatirialoptions] = useState([]);
    const [EmployeesNamesoptions, setEmployeesNamesoptions] = useState([]);
    const animatedComponents = makeAnimated();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption_source, setselectedOption_source] = useState(null); 

    useEffect(() => {

        const fetchData = async () => {
                    
          axios.get(ApiURL +'RepositoryMaterials/GetAll',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              const Material_holder=[];
              data.data.materials.map((item)=>{
                Material_holder.push({
                  value:item.id,
                  label:item.name,
                  defaultPrice:item.defaultPrice})
                
              });
              setMatirialoptions(Material_holder);
              
              const employees_holder=[];
              data.data.employees.map((item)=>{
                employees_holder.push({ value:item.id,
                  label:item.workshopName})
                
              })
              
              setEmployeesNamesoptions(employees_holder);
              
            })
            .catch((err)=>{
              SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
              })
        
        };
    
        fetchData();
      }, []);

    
      const [formData, setFormData] = useState({
          dealerId: 0,
          dealerName:'',
          coolingRooms: false,
          refrigerator: false,
          externalEnvoices: false,
          daily: false,
          tabali: false,
          plastic: false,
          karasta: false,
          fuel: false,
          cars: false,
          employees:true,
          startDate: null,
          endDate: null
          
      });

      const handleChange = (name, value,label) => {
        
          if(value==="")
              value=null;
         
            if(name==='dealerId')
              {
               setFormData({
                 ...formData,
                 [name]: value,
                 dealerName: label
               });
               setselectedOption_source({value,label})
             }
             else
             {
             setFormData({
                 ...formData,
                 [name]: value
               });
             }
           
      };

      const handleSubmit = async (e) => {
        Setflag(true);
        if(!(formData.dealerId<1))
        {
            console.log(formData);
          try {
            setIsLoading(true); // Start loading
          const res = await axios.post(ApiURL+'Billing/GeneratePdf', formData, {
            headers: {
              'Accept': 'text/plain',
              'Content-Type': 'application/json',
               'Authorization': `bearer ${userInfo_Now.auth.token}`
            },
            responseType: 'blob', 
          });
          // Step 3: Create a Blob from the response and a URL to open or display it
    const blob = new Blob([res.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

            // Option 1: Open PDF in a new tab
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'billing.pdf'); // or any other name you want
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
                  
          
        } catch (error) {      
          SetAlert({Activate:true,msg:error+'تأكد من الاتصال بالانترنت'});
         
        }
        finally {
            setIsLoading(false); // End loading
        }
        }
      };

    return (
        <>
            <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
       <div className='parent-Dmaterial'>
       <div className='child-Dmaterial'>
       
       {!isLoading && <> <div style={{margin:'10px',textAlign:'center'}}>
             <h3> كشف حساب العمال</h3></div>  
            <div className="mb-3">
                  <label className="form-label">
                  اسم الورشة
                  </label>
                  <Select
                      id='Select_dealerId_'
                      components={animatedComponents}
                      options={EmployeesNamesoptions}
                      value={selectedOption_source}
                      onChange={(selectedOption) => handleChange('dealerId', selectedOption===null?0:selectedOption.value,selectedOption===null?'':selectedOption.label)}
                      isClearable
                      isSearchable
                      placeholder="اختر ورشة ..."
                    />
                 {formData.dealerId<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار مصدر</p>)}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  من تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  defaultValue={formData.startDate}
                  onChange={(e)=>{handleChange('startDate',e.target.value)}}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">
                  حتى تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  defaultValue={formData.endDate}
                  onChange={(e)=>{handleChange('endDate',e.target.value)}}
                />
            </div>
            
                <button className='btn-Add' onClick={()=>{handleSubmit()}} >طباعة</button>
                </>}

                {isLoading && (
            <div style={{textAlign:'center'}}>
                <TailSpin
                    wrapperClass={'loading-kh-new'}
                    height="400"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="loading"
                />
            </div>
        )}
                </div> 
        
       
        </div>
      
        
        </>
    );
}

export default StatementOfAccount;
