import React from 'react';
import DataList from './DataList';
import PieChartKh from './PieChartKh';
import AreaChartKh from './AreaChartKh';

const CardWithReaChart = ({title,data}) => {
    // const Data = [
    //     { date: '08/03', value: -1500000 },
    //     { date: '08/04', value: 1000000 },
    //     { date: '08/05', value: 1000000 },
    //     { date: '08/06', value: 20000000 },
    //     { date: '08/07', value: 15000000 },
    //     { date: '08/08', value: 10000000 },
    //     { date: '08/09', value: +9000000 },
    //   ];
    
    return (
      <>
        <div className='CardWithPie-Parent'>
           <div><h3>{title}</h3></div>
            <div className='CardWithBar-SubParent' style={{direction:'rtl'}}>
              <AreaChartKh data={data} color="red" />
              </div>
              
              </div>
              <br/>
      </>
       
    );
}

export default CardWithReaChart;
