import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const AddBoxesDetails = ({setFormData,formData,subformData,indexOfsubFdata,flag,IsEnteringNotExiting}) => {

    const animatedComponents = makeAnimated();
    const [local_form,setlocal_form]=useState({
        id: null,
        direction: '',
        count: '',
        colorType: ''
    });

    const BoxesStatusOptions= (IsEnteringNotExiting ?[
        {
            value:'داخل فارغ',
            label:'داخل فارغ'
        },
        {
            value:'داخل ممتلئ',
            label:'داخل ممتلئ'
        }
    ] :
    [
        {
            value:'خارج',
            label:'خارج'
        }
    ])
    
   
    const ColorTypeOptions=[
        {
            value:0,
            label: 'أبيض'
        },
        {
            value:1,
            label: 'أزرق'
        },
        {
            value:2,
            label: 'أسود'
        },
        {
            value:3,
            label: 'أخضر'
        },
        {
            value:4,
            label: 'بني'
        }
    ]
       
    const handleChange = (name, value) => {

        var helper_subformData= JSON.parse(JSON.stringify(formData));

       
        helper_subformData.boFOpDetails[indexOfsubFdata][name]=value;

        setFormData(helper_subformData);
        setlocal_form({
            ...local_form,
            [name]:[value]
        })
        
      };

        //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
      
        // Prevent the page/container scrolling
        e.stopPropagation()
      
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      //#endregion

    return (
        <div className='R-subgroup'>
            <div>
            <div className="mb-3">
                  <label className="form-label">
                  حالة الصناديق:
                  </label>
                  <Select
                      id='Select_direction'
                      components={animatedComponents}
                      options={BoxesStatusOptions}
                      defaultValue={local_form.direction}
                      onChange={(selectedOption) => handleChange('direction', selectedOption===null?0:selectedOption.label)}  
                      
                      placeholder="اختر حالة..."
                      
                    />
                  
                 {local_form.direction.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار حالة الصناديق</p>)}
              </div>
             <div className="mb-3">
                  <label className="form-label">
                  نوع الصندوق:
                  </label>
                  <Select
                      id='Select_colorType'
                      components={animatedComponents}
                      options={ColorTypeOptions}
                      defaultValue={local_form.colorType}
                      onChange={(selectedOption) => handleChange('colorType', selectedOption===null?0:selectedOption.label)}  
                      isClearable
                      isSearchable
                      placeholder="اختر نوع..."
                      
                    />
                 {subformData.colorType.length<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار نوع للصندوق</p>)}
              </div>
  
              <div className="mb-3"> 
                <label className="form-label">
                  عدد الصناديق
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="count"
                  value={local_form.count}
                  onChange={(e)=>{handleChange('count',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                              />
                {subformData.count<0.01 &&flag&& ( <p className="validationReg"> *الرجاء إدخال عدد الصناديق </p>)}
              </div>
              
              </div>
        </div>
    );
}

export default AddBoxesDetails;
