import { faAppleWhole, faBoxesStacked, faBoxOpen, faCalendarDays, faComments, faHandshake, faMoneyBill1Wave, faSackDollar, faScaleBalanced, faTractor, faWeightScale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import axios from 'axios';
import { ApiURL } from '../../App';
import Skeleton from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';
import { UserInfokh } from '../../Contexts/UserContext';

import DeleteConfirmation from '../../Components/Delete Confirmation component/DeleteConfirmation';
import KhAlert from '../../Components/Alert/KhAlert';


const BoxOfUSerDetailed = () => {
    const { setitem,trager_itemChange,settrager_itemChange } = useOutletContext();

//
const queryParameters = new URLSearchParams(window.location.search);  // to get userId form url
const userId = queryParameters.get("id"); 

const navigate=useNavigate();


const [data, setData] = useState(
  {
    id: 0,
    count: 0,
    direction: "خارج",
    boFUserId: 0,
    boFUserName: "", 
    date: "2024-08-20T14:00:37.697Z",
    notes: "string",
    boFOpDetails: [
        {
        id: 0,
        colorType: "string",
        count: 0
        }
    ]
  });
const [loading,setloadnig]=useState(true);
const [alert_,SetAlert]=useState({Activate:false,msg:''});

const userInfo_Now =useContext(UserInfokh);

const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});

function handleDeleteClick(id) {
  setDeleteConfirmation({'active':true,'id':id});
}
const handleClose = () => {
  setDeleteConfirmation({'active':false,'id':0});
};

function handleConfirm() {
  // Perform the delete action here
  DeleteHundel();
  setDeleteConfirmation({'active':false,'id':0});
}

useEffect(()=>{

    const fetchData = async () => {
        setloadnig(true);
        try {
        const res=  await axios.get(ApiURL+'BoxesField/GetOperationDetailed?id='+userId,{   
          headers:{
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
          });
          setData(res.data);
            setloadnig(false);     
          }
          catch(err)
          {
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            setloadnig(false);   
         
        }
      
      };
     
        fetchData();
        //window.history.replaceState(null,'','/AllBoxesMain');
    },[]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

    
    async function DeleteHundel()
    {
      setloadnig(true);  
      const Func_Param=ApiURL+`BoxesField/DeleteAsync?id=${data.id}`;
      try{
       
        const res=await axios.options(Func_Param,{            
          headers:{
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
      });
   
        if(res.data)
          {
            // Refresh the page
            setitem((prevData) => prevData.filter(item => item.id !== data.id));
            settrager_itemChange(trager_itemChange+1);
            window.history.replaceState(null,'','/BoxesOfUser?id='+data.boFUserId+'&name='+data.boFUserName);
            navigate('/BoxesOfUser?id='+data.boFUserId+'&name='+data.boFUserName, { replace: true });
           // window.location.href = '/BoxesOfUser?id=' + data.userId + '&name=' + data.userName;
          }
          else{
            SetAlert({Activate:true,msg:'يوجد خطأ بالبيانات'});
          }
      }
      catch(error_)
      {
       
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
        
      }

      setloadnig(false); 
    } 


return (
    <>
    <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <div className='parent-Dmaterial'>
            <div className='child-Dmaterial' style={{width:'100%', left:'0px'}}>
            {!loading && <> <span className='close-kh' onClick={()=>{navigate('/BoxesOfUser?id='+data.userId+'&name='+data.userName)}}> X </span>
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faCalendarDays} /> التاريخ:  </span> {formatDate(data.date)} </div> 
               
               <div className='Details-kh'> <span> <FontAwesomeIcon icon={faHandshake} /> الاسم: </span>  {data.boFUserName} </div> 
              
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faBoxesStacked} /> عدد الصناديق الكلي : </span>  {data.count} </div>
               
               <fieldset className='Add-fieldset'>
                  <legend>التفاصيل</legend>

              <div className='table-container' style={{height:'125px', maxHeight:'235px',padding:'1px'}}> 
              <table className="custom-table" style={{margin:'0'}}>
                <thead style={{fontSize:'12px', textAlign:'center'}}>
                    <tr>
                      <th>نوع الصندوق</th>
                      <th>الحالة </th>
                      <th> العدد </th>
                      
                    </tr>
            </thead>
            <tbody style={{fontSize:'12px', textAlign:'center'}}>
                {data.boFOpDetails.map((row, index) => (
                <tr key={index}>
                    <td>
                       {row.colorType}
                    </td>
                    <td>
                       {row.direction}
                    </td>
                    <td>
                      {row.count}
                    </td>
                   
                </tr>
                ))}
            </tbody>
             </table>
                </div>
                </fieldset>

              <div className='Details-kh'> <span><FontAwesomeIcon icon={faComments} /> ملاحظات: </span><div className='Note-kh'> {data.notes} </div>  </div> 
               <div className='Details-kh'> <button className='btn-Add btn-delete' onClick={()=>{handleDeleteClick()}}>حذف</button> </div>  
               </>}
               {loading && <>
                <div className='Details-kh'> <Skeleton height='10px' width='200px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                <div className='Details-kh'> <Skeleton height='150px'  /></div>
               </>}  
            </div>

            <DeleteConfirmation
        show={showDeleteConfirmation}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
        </div>
        
         
    </>
);
}

export default BoxOfUSerDetailed;
