import React, { useContext, useEffect, useState } from 'react';
import KhAlert from '../../Components/Alert/KhAlert';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import AllBoxesAsTypeCom from '../Components/AllBoxes/AllBoxesAsTypeCom';
import NotFound from '../../Components/NotFound/NotFound';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';
import Operations from '../Components/Boxes of user/Operations';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { ApiURL } from '../../App';

const BoxesOfUser = () => {

    
    const queryParameters = new URLSearchParams(window.location.search);  // to get userId form url
    const userId = queryParameters.get("id"); 
    const userName = queryParameters.get("name"); 

    const userInfo_Now =useContext(UserInfokh);
    const [Alldata, setAlldata] = useState([]);
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});

    const [item, setitem] = useState([]);
    const [trager_itemChange, settrager_itemChange] = useState(0);
    
    useEffect(() => {
        const fetchData = async () => {
            setloadnig(true);
          
          axios.get(ApiURL+'BoxesField/GetBoFOperationsPage_ByUserId?userId='+userId,{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
                console.log(data.data)
              setAlldata(data.data.topOfThePage);
            
              setitem(data.data.bottomOfThePage)
              setloadnig(false);
              
            })
            .catch((err)=>{
                //setloadnig(false);
                SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                       
              })
        
        };
      
        fetchData();
      }, [trager_itemChange]);

    return (
        <>
         <Outlet context={{ setitem ,trager_itemChange, settrager_itemChange}}/>
            <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <UserHeader title={`الصناديق الحقلية / ${userName}`} SearchActivator={false} Active={0}/>
            <div className='background-kh'></div>
            <div id='scrollableDiv' style={{ height:'100vh',overflow: 'auto',padding: '5px'}}>               
                <AllBoxesAsTypeCom title='الصناديق حسب النوع' data={Alldata} isInUserPage={true}/>
                
             
                <div className='CardWithPie-Parent'>
                <div><h3>العمليات</h3></div>
             <div className='GetAll-pages-kh'>
             {item.map((one,index)=>(
                <Operations key={index} props={one}  />  
             ))}
            {!loading &&item.length===0 && <NotFound />}
                </div>
          
        </div>
        
        
                
            <br/> 
            </div>
            {loading && <Skeleton_kh />} 
        </>
    );
}

export default BoxesOfUser;

