import { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";
import { UserInfokh } from "../Contexts/UserContext";

export default function RequireAuth(){
   
    const userInfo_Now =useContext(UserInfokh);
  
    return (
        userInfo_Now.auth.email?<Outlet/>:<Navigate to ='/Login'/>
    );
}