import React, { useContext, useEffect, useState } from 'react';
import CardWithPie from '../../Components/Main Page/CardWithPie';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import CardWithBar from '../../Components/Main Page/CardWithBar';
import CardWithReaChart from '../../Components/Main Page/CardWithReaChart';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';

const MainPage = () => {
  const userInfo_Now =useContext(UserInfokh);
  const navigate =useNavigate();
    const [Alldata, setAlldata] = useState([]);
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [Alloperationdata,setAlloperationdata] =useState([
      { name: 'إيرادات', value: 0 },
      { name: 'مصاريف', value:  0},
      { name: 'المجموع', value: 0 }
    ]);
    const [Thesafe,setThesafe] =useState([
      { name: 'الداخل', value: 0 },
      { name: 'الخارج', value: 0 },
      { name: 'الموجود', value: 0 }
    ]);
    const [redData,setredData] =useState([
      { date: '08/03', value: 0 },
          { date: '08/04', value: 0 },
          { date: '08/05', value: 0 },
          { date: '08/06', value: 0 },
          { date: '08/07', value: 0},
          { date: '08/08', value: 0 },
          { date: '08/09', value: 0 },
    ]);
    const [greenData,setgreenData] =useState([
      { date: '08/03', value: 0 },
      { date: '08/04', value: 0 },
      { date: '08/05', value: 0 },
      { date: '08/06', value: 0 },
      { date: '08/07', value: 0 },
      { date: '08/08', value: 0 },
      { date: '08/09', value: 0 },
    ]);
    const [AlloptData,setAlloptData] =useState([
      { date: '08/03', value: 0 },
      
    ]);
    
    

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const year = date.getFullYear();
      
          return `${day}/${month}`;
      };

      if(userInfo_Now.auth.role!=='Admin'){
        navigate('/AllBoxesMain');
       }
       
  useEffect(() => {
    const fetchData = async () => {
        setloadnig(true);
      
      axios.get(ApiURL+'TotalFunds/GetAllOpt',{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
        }).then((data)=>{
          //setAlldata(data.data);
          setAlloperationdata([
            { name: 'الإيرادات', value: data.data.earns.totalIn },
            { name: 'المصاريف', value: data.data.earns.totalOut},
            { name: 'الربح', value: data.data.earns.profits }
          ]);
          setThesafe([
            { name: 'الداخل', value:  data.data.theSafe.totalIn},
            { name: 'الخارج', value: data.data.theSafe.totalOut },
            { name: 'الموجود', value: data.data.theSafe.current }
          ])
          const redData_helper = [];
      const greenData_helper = [];
          data.data.last7Days_revenue_Opt.map((item)=>{
            greenData_helper.push({ date: formatDate(item.date), value: item.cost });
          })
          data.data.last7Days_expenses_Opt.map((item)=>{
            redData_helper.push({ date: formatDate(item.date), value: item.cost });
          })
          const AlloptData_helper=[];
          data.data.allOpt.map((item)=>{
            AlloptData_helper.push({ date: formatDate(item.date), value:item.cost });
          });

          setAlloptData(AlloptData_helper);
          setgreenData(greenData_helper);
          setredData(redData_helper);
          setloadnig(false);
          console.log(data.data);
        })
        .catch((err)=>{
            //setloadnig(false);
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                   
          })
    
    };
  
    fetchData();
  }, []);



    return (
        <>
        <Outlet />
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <UserHeader title='الصندوق' SearchActivator={false} Active={0}/>
            <div className='background-kh'></div>
            <div id='scrollableDiv' style={{ height:'100vh',overflow: 'auto',padding: '5px'}}>
            <CardWithPie title='جميع العمليات' data={Alloperationdata}/>
            <CardWithPie title='الخزنة' data={Thesafe}/>
            <CardWithBar title='معاملات آخر 7 أيام' greenData={greenData} redData={redData}/>
            <CardWithReaChart title='تفاصيل العمليات' data={AlloptData}/>
            <br/> 
            </div>
            {loading && <Skeleton_kh />} 
        </>
    );
}

export default MainPage;
