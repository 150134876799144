import React from 'react';
import DataList from './DataList';
import PieChartKh from './PieChartKh';

const CardWithPie = ({title,data}) => {
    // const data = [
    //     { name: 'إيرادات', value: 400000000 },
    //     { name: 'مصاريف', value:  300000000},
    //     { name: 'المجموع', value: 100000000 }
    //   ];
    return (
        <div className='CardWithPie-Parent'>
            <div><h3>{title}</h3></div>
            <div className='CardWithPie-SubParent'>
                <div>
                <DataList data={data}/>
                </div>
                <div>
                <PieChartKh data={data}/>
                </div>
            </div>
        </div>
    );
}

export default CardWithPie;
