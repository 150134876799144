import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';


const AddDaily = () => {

  const userInfo_Now =useContext(UserInfokh);
  const [alert_,SetAlert]=useState({Activate:false,msg:''});
  const [flag,Setflag]=useState(false);

  const [Matirialoptions, setMatirialoptions] = useState([]);
  const [farmerNamesoptions, setfarmerNamesoptions] = useState([]);
  const [SupplierOfFarmsNamesoptions, setSupplierOfFarmsNamesoptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
                
      axios.get(ApiURL +'RepositoryMaterials/GetAllByCategoryId?catId='+1,{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
        }).then((data)=>{
          const Material_holder=[];
          data.data.materials.map((item)=>{
            Material_holder.push({
              value:item.id,
              label:item.name,
              defaultPrice:item.defaultPrice})
            
          });
          setMatirialoptions(Material_holder);
          
          const Supplier_holder=[];
          data.data.suppliers.map((item)=>{
            Supplier_holder.push({ value:item.supplierName,
              label:item.supplierName})            
          })
          setfarmerNamesoptions(Supplier_holder);

       
          const SupplierOfFarms_holder=[];
          data.data.soFarms.map((item)=>{
            SupplierOfFarms_holder.push({ value:item.supplierName,
              label:item.supplierName})            
          })
          setSupplierOfFarmsNamesoptions(SupplierOfFarms_holder);
          
        })
        .catch((err)=>{
          SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
         console.log(err);
          })
    
    };

    fetchData();
  }, []);

  const animatedComponents = makeAnimated();
  const [formData, setFormData] = useState({
    repositoryMaterialId: null,
    totalBoxes: '',
    balanceCardWeight: '',
    emptyBoxesWeight: 2,
    codeNumber: '',
    buyPriceOfUnit: '',
    farmerName: '',
    supplier: '',
    cuttingCostOfUnit:'',
    waxingCostOfUnit:'',
    date: '',
    notes: ''
  });
 
  const handleCreateOptionsupplier = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setfarmerNamesoptions((prevOptions) => [...prevOptions, newOption]);
  
  };
  const handleCreateOptionsupplierOfFarms = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setSupplierOfFarmsNamesoptions((prevOptions) => [...prevOptions, newOption]);
  
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    
  };
  function RestValues(){
    Setflag(false);
    setFormData({
      repositoryMaterialId:''+ formData.repositoryMaterialId,
      totalBoxes: '',
      balanceCardWeight: '',
      emptyBoxesWeight: 2,
      codeNumber: '',
      buyPriceOfUnit: '',
      cuttingCostOfUnit:'',
      waxingCostOfUnit:'',
      farmerName: formData.farmerName,
      supplier: formData.supplier,
      date: '',
      notes: ''
    });    
  }

  const handleSubmit = async (e) => {
    Setflag(true);
    if(!(formData.repositoryMaterialId<1||formData.farmerName.length<2 ||formData.supplier.length<2 || formData.totalBoxes<1 
      || formData.cuttingCostOfUnit.length<1 || formData.waxingCostOfUnit.length<1 || formData.balanceCardWeight<0.01|| formData.emptyBoxesWeight<0.01|| formData.buyPriceOfUnit<0.01||formData.date.length<1))
    {
      try {
      const res = await axios.post(ApiURL+'Daily/AddNewDaily', formData, {
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
           'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
       
      });
      RestValues();
      SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
      
    } catch (error) {      
      SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
   
     
    }
    }
  };

  //#region OnWheel Prevent Change
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()
  
    // Prevent the page/container scrolling
    e.stopPropagation()
  
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  //#endregion
  return (
    <div> 
      <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
      <UserHeader title='شراء فاكهة' SearchActivator={false}/>
      {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
      <div className='form-addRepo' >
            <div className="mb-3">
                <label className="form-label">
                المادة:
                </label>
                <Select
                    id='Select_repositoryMaterialId_'
                    components={animatedComponents}
                    options={Matirialoptions}
                    defaultValue={formData.repositoryMaterialId}
                    onChange={(selectedOption) => handleChange('repositoryMaterialId', selectedOption===null?0:selectedOption.value)}  
                    isClearable
                    isSearchable
                    placeholder="اختر فاكهة..."
                    
                  />
               {formData.repositoryMaterialId<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار فاكهة</p>)}
            </div>

            <div className="mb-3">
                <label className="form-label">
                المزارع:
                </label>
                <CreatableSelect
                    id='Select_supplierName_'
                    components={animatedComponents}
                    options={farmerNamesoptions}
                    defaultValue={formData.farmerName}
                    onChange={(selectedOption) => handleChange('farmerName', selectedOption.value)}
                    onCreateOption={handleCreateOptionsupplier}
                    isSearchable
                    placeholder="اختر مزارع أو أضف مزارع جديد..."
                  />
               {formData.farmerName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار مزارع</p>)}
            </div>
           
           
            <div className="mb-3">
                <label className="form-label">
                المورد
                </label>
                <CreatableSelect
                    id='Select_supplierName_'
                    components={animatedComponents}
                    options={SupplierOfFarmsNamesoptions}
                    defaultValue={formData.supplier}
                    onChange={(selectedOption) => handleChange('supplier', selectedOption.value)}
                    onCreateOption={handleCreateOptionsupplierOfFarms}
                    isSearchable
                    placeholder="اختر مورد أو أضف مورد جديد..."
                  />
                {formData.supplier.length<2 &&flag&& ( <p className="validationReg"> *الرجاء إدخال مورد </p>)}
            </div>
            
            <div className="mb-3"> 
              <label className="form-label">
                عدد الصناديق
              </label>
              <input
                className="form-control"
                type="number"
                name="totalBoxes"
                value={formData.totalBoxes}
                onChange={(e)=>{handleChange('totalBoxes',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.totalBoxes<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال عدد الصناديق </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
                الوزن القائم
              </label>
              <input
                className="form-control"
                type="number"
                name="balanceCardWeight"
                value={formData.balanceCardWeight}
                onChange={(e)=>{handleChange('balanceCardWeight',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.balanceCardWeight<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال الوزن القائم  </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
                وزن الصندوق الفارغ 
              </label>
              <input
                className="form-control"
                type="number"
                name="emptyBoxesWeight"
                value={formData.emptyBoxesWeight}
                onChange={(e)=>{handleChange('emptyBoxesWeight',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.emptyBoxesWeight<0.01 &&flag&& ( <p className="validationReg"> *الرجاء إدخال وزن الصندوق الفارغ   </p>)}
            </div>

           
            <div className="mb-3"> 
              <label className="form-label">
              سعر الكيلو الواحد 
              </label>
              <input
                className="form-control"
                type="number"
                name="buyPriceOfUnit"
                value={formData.buyPriceOfUnit}
                onChange={(e)=>{handleChange('buyPriceOfUnit',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.buyPriceOfUnit<0.01 &&flag&& ( <p className="validationReg"> *الرجاء تحديد السعر</p>)}
            </div>

            
            <div className="mb-3"> 
              <label className="form-label">
              كلفة القص للكيلو
              </label>
              <input
                className="form-control"
                type="number"
                name="cuttingCostOfUnit"
                value={formData.cuttingCostOfUnit}
                onChange={(e)=>{handleChange('cuttingCostOfUnit',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.cuttingCostOfUnit.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد كلفة القص</p>)}
            </div>

            <div className="mb-3"> 
              <label className="form-label">
              كلفة التشميع للكيلو
              </label>
              <input
                className="form-control"
                type="number"
                name="waxingCostOfUnit"
                value={formData.waxingCostOfUnit}
                onChange={(e)=>{handleChange('waxingCostOfUnit',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.waxingCostOfUnit.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد كلفة التشميع</p>)}
            </div>
           
            <div className="mb-3">
              <label className="form-label">
                التاريخ
              </label>
              <input
                className="form-control"
                type="date"
                name="date"
                value={formData.date}
                onChange={(e)=>{handleChange('date',e.target.value)}}
              />
               {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
            </div>
            <div className="mb-3">
                <label className="form-label">
                ملاحظات
                </label>
                <textarea 
                type='text'
                className="form-control"
                name="notes" 
                value={formData.notes} 
                onChange={(e)=>{handleChange('notes',e.target.value)}}
                placeholder={"إضافة ملاحظات..."}               
                />
              
            </div>
       
        <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
        </div>
      {/* </form> */}
    </div>
    
  );
};

export default AddDaily;
