import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const PieChartKh = ({data}) => {

    const items = [
        { name: data[0].name, value: data[0].value },
        { name: data[1].name, value: data[1].value },
        { name: 'null', value: (data[0].value===0&&data[1].value===0)?1:0 }
      ];
    
      const COLORS = ['#50ad4d', '#f24334','#c4c4c4'];

    return (
        <>
            <PieChart width={90} height={90}>
                <Pie
                data={items}
                innerRadius={16}
                outerRadius={40}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
  </PieChart>
        </>
    );
}

export default PieChartKh;
