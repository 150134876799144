import React, { useContext, useEffect, useState } from 'react';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import axios from 'axios';
import { ApiURL } from '../../App';
import { UserInfokh } from '../../Contexts/UserContext';
import KhAlert from '../../Components/Alert/KhAlert';
import { Outlet, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import DeleteConfirmation from '../../Components/Delete Confirmation component/DeleteConfirmation';

const FinancialPayments = () => {

    const queryParameters = new URLSearchParams(window.location.search);  // to get RepositoryId form url
    const EntitlementId = queryParameters.get("EntitlementId"); 
    const supplierName = queryParameters.get("supplierName"); 

    const [data,setdata]=useState([]);
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});

    const navigate =useNavigate();
    const userInfo_Now =useContext(UserInfokh);

    const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});

    function handleDeleteClick(id) {
      setDeleteConfirmation({'active':true,'id':id});
    }
    const handleClose = () => {
      setDeleteConfirmation({'active':false,'id':0});
    };

    function handleConfirm(id) {
      // Perform the delete action here
      DeleteHundel(id);
      setDeleteConfirmation({'active':false,'id':0});
    }

      useEffect(()=>{

        const fetchData = async () => {
            setloadnig(true);
            try {
            const res=  await axios.get(ApiURL+'FinancialPayments/GetAllFinancialPayments?EntitlementId='+EntitlementId,{   
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
              });
              setdata(res.data);
              setloadnig(false);     
              }
              catch(err)
              {
                SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                setloadnig(false);   
             
            }
          
          };
         
            fetchData();
           
        },[]);

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
        
            return `${day}/${month}/${year}`;
        };
        async function DeleteHundel(id)
        {
          setloadnig(true);  
          const Func_Param=ApiURL+`FinancialPayments/DeleteFinancialPayment?id=${id}`;
          try{
           
            const res=await axios.options(Func_Param,{            
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
          });
       
            if(res.data)
              {
                // Refresh the page
                //setAlldata((prevData) => prevData.filter(item => item.id !== data.id));
                navigate(0);
               
              }
              else{
                SetAlert({Activate:true,msg:'يوجد خطأ بالبيانات'});
              }
          }
          catch(error_)
          {
           
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            
          }
    
          setloadnig(false); 
        } 
    return (
        <>
        <Outlet />
        <UserHeader title={'الديون-الدفعات/ '+supplierName} SearchActivator={false} Active={4} />
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <div className='table-container'>
        {!loading && <table className="custom-table">
                <thead>
                    <tr>
                    <th>للحذف</th>
                    <th>معلومات الدفعة</th>
                    </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                <tr key={index}>
                    <td>
                   
                    <button className='payment-delete' onClick={()=>handleDeleteClick(row.id)}>
                        حذف
                    </button>
                    </td>
                    <td>
                    <div><strong>التاريخ: </strong> {formatDate(row.paymentDate)}</div>
                    <div><strong>قيمة الدفعة:  </strong>{row.amountPayment}</div>
                   {row.notes.length>0 && <div><strong>الملاحظات : </strong>{row.notes}</div>}
                    
                    </td>
                </tr>
                ))}
            </tbody>
             </table>}
             {loading && <>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='100px'  /></div>
                   </>}  
             <button className='Float-button' onClick={()=>{navigate('/Payments/AddPayment?EntitlementId='+EntitlementId+'&supplierName='+supplierName)}}>+</button>
       
             <DeleteConfirmation
                  show={showDeleteConfirmation}
                  handleClose={handleClose}
                  handleConfirm={handleConfirm}
                />
        </div>
        </>
    );
}

export default FinancialPayments;
