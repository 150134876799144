import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserInfokh } from '../../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../../App';
import UserHeader from '../../../Components/HeaderAndSidebar/UserHeader';
import KhAlert from '../../../Components/Alert/KhAlert';
import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';
import NotFound from '../../../Components/NotFound/NotFound';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmation from '../../../Components/Delete Confirmation component/DeleteConfirmation';


const DailyCheckDetails = () => {
    const queryParameters = new URLSearchParams(window.location.search);  // to get employeeId form url
    const employeeId = queryParameters.get("Id"); 
    const workshopName = queryParameters.get("workshopName"); 

    const [data,setdata]=useState([]);
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});

    const navigate =useNavigate();
    const userInfo_Now =useContext(UserInfokh);
    const [classtd,setclasstd]=useState(['hidden-info']);
    const [classbtn,setclassbtn]=useState(['show-button show-button-down']);
    const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});

    useEffect(()=>{

        const fetchData = async () => {
            setloadnig(true);
            try {
            const res=  await axios.get(ApiURL+'Employee/GetDailyCheckEmployeeByEmployeeId?employeeId='+employeeId,{   
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
              });
              setdata(res.data);
              setloadnig(false);  
              const newClasstd = [];
              const newclassbtn = []; 

              res.data.map((item)=>{
                newClasstd.push('hidden-info');
                newclassbtn.push('show-button show-button-down');
              })
              setclasstd(newClasstd);
              setclassbtn(newclassbtn);
                
              }
              catch(err)
              {
                SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                setloadnig(false);   
             
            }
          
          };
         
            fetchData();
          
        },[]);

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
        
            return `${day}/${month}/${year}`;
        };

        //#region delete element

        
        function handleDeleteClick(id) {
            setDeleteConfirmation({'active':true,'id':id});
        }
     
        const handleClose = () => {
            setDeleteConfirmation({'active':false,'id':0});
        };
      
        function handleConfirm(id) {
            // Perform the delete action here
            DeleteHundel(id);
            setDeleteConfirmation({'active':false,'id':0});
        }
       

        //#endregion


        async function DeleteHundel(id)
        {
          setloadnig(true);  
          const Func_Param=ApiURL+`Employee/DeleteDailyCheck?DailyCheckId=${id}`;
          try{
           
            const res=await axios.options(Func_Param,{            
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
          });
       
            if(res.data)
              {
                // Refresh the page
                //setAlldata((prevData) => prevData.filter(item => item.id !== data.id));
                navigate(0);
               
              }
              else{
                SetAlert({Activate:true,msg:'يوجد خطأ بالبيانات'});
              }
          }
          catch(error_)
          {
           
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            
          }
    
          setloadnig(false); 
        } 

        function toggleInfo(id)
        {
         
          const newClasstd = [...classtd];
          const newclassbtn = [...classbtn];
          
          if(newClasstd[id]==='show-info')
            {
              newClasstd[id]= 'hidden-info';
              newclassbtn[id]='show-button show-button-down'
            }
          else
          {
            newClasstd[id]= 'show-info';
            newclassbtn[id]='show-button show-button-up'
          }
          setclasstd(newClasstd);
          setclassbtn(newclassbtn);
        }

    return (
        <>
         <Outlet />
        <UserHeader title={'تفقد العمال/ '+workshopName} SearchActivator={false} Active={7} />
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <div className='table-container'> 
        {!loading && data.length===0 && <NotFound />}
        {!loading &&  <table className='table-res'>
     
     <tbody>
       {data.map((item,index) => (
         <tr key={item.id} className='tr-res'>
           <td className='show-info'>
           <span>التاريخ: </span> {formatDate(item.date)}
           </td>
           <td className={classtd[index]}>
             <span >عدد الشباب:</span> <span><NumericFormat decimalScale={0} value={item.menCount} displayType={'text'} thousandSeparator={true} /></span>
           </td>
           <td className={classtd[index]}>
             <span > عدد البنات:</span> <span><NumericFormat decimalScale={0} value={item.girlsCount} displayType={'text'} thousandSeparator={true} /></span>
           </td>
           <td className={classtd[index]}>
             <span >عدد ساعات العمل النظامي:</span> <span><NumericFormat decimalScale={1} value={item.normJobHCount} displayType={'text'} thousandSeparator={true} /> ساعة</span>
           </td>
           <td className={classtd[index]}>
             <span > عدد ساعات العمل الإضافي  :</span> <span><NumericFormat decimalScale={1} value={item.addJobHCount} displayType={'text'} thousandSeparator={true} /> ساعة</span>
           </td>
           <td className={classtd[index]}>
             <span>  الأجر الساعي للشباب :</span> <span><NumericFormat decimalScale={1} value={item.normHWageM} displayType={'text'} thousandSeparator={true} /> ساعة</span>
           </td>
           <td className={classtd[index]}>
             <span> الأجر الساعي للبنات  :</span> <span><NumericFormat decimalScale={1} value={item.normHWageG} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className={classtd[index]}>
             <span> أجر ساعة العمل الإضافي :</span> <span><NumericFormat decimalScale={1} value={item.additionalWorkingHourWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className={classtd[index]}>
             <span> الراتب اليومي :</span> <span><NumericFormat decimalScale={1} value={item.totalWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className={classtd[index]}>
             <span> أجار سرفيس :</span> <span><NumericFormat decimalScale={1} value={item.reward} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           
           <td className={classtd[index]}>
             <span> الحسم :</span> <span><NumericFormat decimalScale={1} value={item.discount} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className='show-info'>
             <span > الأجر اليومي الكلي :</span> <span><NumericFormat decimalScale={1} value={item.resultWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className='show-info'>
             <span > المبلغ المدفوع :</span> <span><NumericFormat decimalScale={1} value={item.paidWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
           </td>
           <td className={classtd[index]}>
             <span>  الملاحظات:</span> {item.notes}
             
           </td>
           <td className={classtd[index]}>
             <span>  </span> <span className='Daily-delete' onClick={()=>{handleDeleteClick(item.id)}}><FontAwesomeIcon icon={faTrashCan} /> حذف</span>
             
           </td>
           <td className={classbtn[index]} style={{padding:'0'}} onClick={()=>toggleInfo(index)}></td>
         </tr>
       ))}
     </tbody>
            </table>}
       

        {loading && <>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='85%' /></div>
                    <div className='Details-kh'> <Skeleton height='100px'  /></div>
                   </>}  
                   <button className='Float-button' onClick={()=>{navigate('/DetailsDailyCheck/AddCheck?Id='+employeeId+'&workshopName='+workshopName)}}>+</button>
        </div>
        <DeleteConfirmation
        show={showDeleteConfirmation}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
        </>
    );
}

export default DailyCheckDetails;
