import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { Area, AreaChart, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import CustomizedAxisTick from './testCustomLabel 1/CustomizedAxisTick';




const AreaChartKh = ({data}) => {



    return (
        <>
         <ResponsiveContainer width="100%" height={180} >
         <AreaChart
  width={730}
  height={250}
  data={data}
  margin={{ top: 10, right: 20, left: -50, bottom: 0 }}>
  <XAxis dataKey="date" />
  <YAxis type="number" tick={<CustomizedAxisTick />} padding={{bottom:10}}/>
  <Area dataKey="value" stroke="#8884d8" fill="#8884d8" />
  <Tooltip />
</AreaChart>
            {/* <AreaChart data={data}
                    margin={{ top: 10, right: 20, left: -50, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis type="number" tick={<CustomizedAxisTick />} padding={{bottom:10}}/>
                        
                        
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" >
                    <LabelList dataKey="value" position="top" />
        </Area>
                   
            </AreaChart> */}
        </ResponsiveContainer>
        </>
    );
}

export default AreaChartKh;
