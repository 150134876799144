import { createContext, useState } from "react";

export const StaticInfo = createContext();

export default function StaticInfoProvider({children}){

   
    const [StaticDataInfo,setStaticDataInfo]= useState({'Matirialoptions':[],'selectedMatirialoption':null,
                                        'supplieroptions':[],'selectedsupplieroption':null,
                                        'supplierOfFarmsoptions':[],'selectedsupplierOfFarmsoption':null,
                                        'Buyeroptions':[],'selectedBuyeroption':null
    });
    return (
        <StaticInfo.Provider value={{StaticDataInfo,setStaticDataInfo}}>
            {children}
        </StaticInfo.Provider>
    )
}