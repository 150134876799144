import React from 'react';

const DeleteConfirmation = ({ show, handleClose, handleConfirm }) => {
    if (!show.active) {
      return null;
    }
    return (
      <div className="DC-modal">
        <div className="DC-modal-content">
          <h4>تأكيد الحذف</h4>
          <p>هل أنت متأكد أنك تريد حذف هذا العنصر؟</p>          
          <button onClick={()=> handleConfirm(show.id)}>تأكيد الحذف</button>
          <button onClick={handleClose}>إلغاء</button>
        </div>
      </div>
    );
  };
export default DeleteConfirmation;
