import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { useNavigate } from 'react-router-dom';
import { UserInfokh } from '../../Contexts/UserContext';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import AddBoxesDetails from '../Components/Boxes of user/AddBoxesDetails';
import Select from 'react-select';

const AddBoxes = ({IsEnteringNotExiting}) => {const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
    const [RemoveActivate,setRemoveActivate]=useState(false);
  
    const [BuyerNamesoptions, setBuyerNamesoptions] = useState([]);

    const navigate=useNavigate();
    const animatedComponents = makeAnimated();

    useEffect(() => {
      const fetchData = async () => {
                  
        axios.get(ApiURL +'BoxesField/GetBoF_Info',{   
          headers:{
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
          }).then((data)=>{
            const Material_holder=[];
           
            
            const buyers_holder=[];
            data.data.users.map((item)=>{
                buyers_holder.push({ value:item.userName,
                label:item.userName})
              
            })
            setBuyerNamesoptions(buyers_holder);
            
          })
          .catch((err)=>{
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            })
      
      };
  
      fetchData();
    }, []);
  
   
    const [subformData, setsubformData] = useState({
      id: 0,
      direction: '',
      count: 0,
      colorType: ''
  });
    
    const [formData, setFormData] = useState({
        id: 0,        
        boFUserName: '',
        boFOpDetails:[subformData],       
        date: new Date().toISOString().split('T')[0],
        notes: ''
    });
   
    const handleCreateOptionsupplier = (inputValue) => {
      const newOption = { label: inputValue, value: inputValue };
      setBuyerNamesoptions((prevOptions) => [...prevOptions, newOption]);
    
    };
  
    const handleChange = (name, value) => {
      setFormData({
        ...formData,
        [name]: value
      });
      
    };
    function RestValues(){
      Setflag(false);
     
      setFormData({
        id: null,
        direction: 'خارج',
        boFOpDetails:[subformData],        
        boFUserName: formData.boFUserName,
        date: new Date().toISOString().split('T')[0],
        notes: ''
        });    
    }
  
    const handleSubmit = async (e) => {
      Setflag(true);
      if(!(formData.boFUserName.length<2 ||formData.date.length<1 ))
      {        
          const hasInvalidDetail = formData.boFOpDetails.some(element => {
            return (
              element.colorType.length<1||
              element.count < 0.01 ||
              element.direction.length<2           
            );
          });

          if (hasInvalidDetail) {
            return; // الخروج من `handleSubmit` بالكامل إذا كان هناك أي عنصر غير صالح
          }
      
        try {
        const res = await axios.post(ApiURL+'BoxesField/AddAsync', formData, {
          headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
             'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
         
        });
        navigate('/AllBoxesMain');
        //SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
        
      } catch (error) {     
        
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
       
      }
      }
    };

    function handleAddGroup()
    {
      const helper_subformData=[...formData.boFOpDetails];
      helper_subformData.push(subformData);

      setFormData({
        ...formData,
        'boFOpDetails':helper_subformData
      });
      setRemoveActivate(true);
    }
    function handleRemoveGroup()
    {
      
      const helper_subformData=[...formData.boFOpDetails];
      helper_subformData.pop();
     
      setFormData({
        ...formData,
        'boFOpDetails':helper_subformData
      });
      if(helper_subformData.length<2)
      {
        setRemoveActivate(false);
      }
    }
    
    //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
      
        // Prevent the page/container scrolling
        e.stopPropagation()
      
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      //#endregion
    return (
      <div> 
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <UserHeader title=' حركة الصناديق الحقلية ' SearchActivator={false}/>
        {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
        <div className='form-addRepo' >

             <div className="mb-3">
                <label className="form-label">
                  التاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={(e)=>{handleChange('date',e.target.value)}}
                />
                 {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
              </div>

              <div className="mb-3">
                  <label className="form-label">
                  الاسم:
                  </label>
                  <CreatableSelect
                      id='Select_supplierName_'
                      options={BuyerNamesoptions}
                      defaultValue={formData.boFUserName}
                      onChange={(selectedOption) => handleChange('boFUserName', selectedOption.value)}
                      onCreateOption={handleCreateOptionsupplier}
                      isSearchable
                      placeholder="اختر اسم أو أضف اسم جديد..."
                    />
                 {formData.boFUserName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار اسم</p>)}
              </div>
             
              <fieldset className='Add-fieldset'>
                  <legend>تفاصيل الألوان:</legend>

                { formData.boFOpDetails.map((item,index)=>
                  <AddBoxesDetails key={index} setFormData={setFormData}
                  formData={formData}
                  subformData={item}
                  indexOfsubFdata={index}
                  flag={flag} 
                  IsEnteringNotExiting={IsEnteringNotExiting}
                  />
                )
                }
                <button className='Add-fieldset-btn-add' onClick={handleAddGroup}>إضافة لون</button>
               {RemoveActivate && <button className='Add-fieldset-btn-remove' onClick={handleRemoveGroup}>حذف لون</button>}
             </fieldset>
              <div className="mb-3">
                  <label className="form-label">
                  ملاحظات
                  </label>
                  <textarea 
                  type='text'
                  className="form-control"
                  name="notes" 
                  value={formData.notes} 
                  onChange={(e)=>{handleChange('notes',e.target.value)}}
                  placeholder={"إضافة ملاحظات..."}               
                  />
                
              </div>
         
          <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
          </div>
        {/* </form> */}
      </div>
      
    );
  };

export default AddBoxes;
