import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faFlaskVial, faHandHoldingDollar, faSackDollar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';

const DetailedDebts = () => {

    const { Alldata } = useOutletContext();

    const queryParameters = new URLSearchParams(window.location.search);  // to get RepositoryId form url
    const id_k = queryParameters.get("Id"); 

    const navigate=useNavigate();

    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [item,setitem]=useState({});
    var [oprations,setoprations]=useState([])
    
    useEffect(()=>{
      
        const tt=Alldata.filter(item1 => item1.id == id_k)
        setitem(tt[0]);
       
        setoprations(tt[0].operations);
        window.history.replaceState(null, '', '/debts');
    },[])
   

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

        
        

    return (
        <>
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <div className='parent-Dmaterial'>
                <div className='child-Dmaterial'>
                 <span className='close-kh' onClick={()=>{navigate('/debts')}}> X </span>
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faTruck} /> التاجر/المصدر:  </span> {item.supplierName} </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faSackDollar} /> المبلغ الاجمالي: </span> <span style={item.totalAmount<0?{color:'red'}:{color:'green'}} ><NumericFormat decimalScale={1} value={item.totalAmount} displayType={'text'} thousandSeparator={true} />  ل.س</span> </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faHandHoldingDollar} />  الدفعات الاجمالية: </span> <span style={item.totalPayments<0?{color:'red'}:{color:'green'}} ><NumericFormat decimalScale={1} value={item.totalPayments} displayType={'text'} thousandSeparator={true} />  ل.س</span> </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faCoins} />   المتبقي: </span> <span style={item.remainder<0?{color:'red'}:{color:'green'}} ><NumericFormat decimalScale={1} value={item.remainder} displayType={'text'} thousandSeparator={true} />  ل.س</span> </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faFlaskVial} /> العمليات: </span><div className='Note-kh'> {oprations.map((one,index)=><div key={index}>{one}<br/></div>  )} </div>  </div>
                  
                   <div className='Details-kh'> <button className='btn-Add btn-delete' onClick={()=>{navigate('/payments?EntitlementId='+item.id+'&supplierName='+item.supplierName)}}>الدفعات المالية</button> </div>  
                 
                  
                </div>
            </div>
            
             
        </>
    );
}

export default DetailedDebts;
