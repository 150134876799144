import React, { useContext, useEffect, useState } from 'react';
import './UserHeaderCss.css'
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleWhole, faFileInvoiceDollar, faGasPump, faGem, faHandHoldingDollar, faHouse, faLayerGroup, faMagnifyingGlass, faPaintbrush, faPersonDigging, faPrint, faReceipt, faScaleBalanced, faScrewdriverWrench, faSnowflake, faTemperatureArrowDown, faTruckMoving, faVanShuttle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UserInfokh } from '../../Contexts/UserContext';

const UserHeader = (probs) => {

    const userInfo_Now =useContext(UserInfokh);
    const user_role=userInfo_Now.auth.role;
    const { SearchActivator = true } = probs;
    const navigate =useNavigate();
  
    const [SidebarActivator,setSidebarActivator]=useState(false);
    const [PageActivator,setPageActivator]=useState([
        true,false,false,false,false,false,false,false,false,false,false,false,false,false,false
    ]);
    useEffect(()=>{
        const helper_= [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
        
        helper_[probs.Active]=true;
        setPageActivator(helper_);
    },[])

    
    function Handel_ColorOnActive(Activated)
    {
        const helper_= [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
        helper_[Activated]=true;
        setPageActivator(helper_);
        // switch(Activated)
        // {
        //     case 1:helper_[Activated]=true;break;
        //     case 2:setColorOnActive_Home('gray');setColorOnActive_Sell('white');setColorOnActive_Rent('gray');setColorOnActive_ColorOnActive_Contact('gray');break;
        //     case 3:setColorOnActive_Home('gray');setColorOnActive_Sell('gray');setColorOnActive_Rent('white');setColorOnActive_ColorOnActive_Contact('gray');break;
        //     case 4:setColorOnActive_Home('gray');setColorOnActive_Sell('gray');setColorOnActive_Rent('gray');setColorOnActive_ColorOnActive_Contact('white');break;
        //     default :break;
        // }
         setSidebarActivator(false);
        
    }

    return (
        <>
            <div className='UserHeader-nav' style={probs.paddingtop!==null?{paddingTop:probs.paddingtop}:{}}>
                <div className='UserHeader-content'>
               
                    <div className='UserHeader-Sidebar'>
                    <span onClick={()=>{setSidebarActivator(true)}}>  <i className="fa fa-bars User-size-x-2"></i></span> 
                   
                    <ul className={`UserHeader-ul ${SidebarActivator?'UserHeader-Active':'UserHeader-Unactive'}`}>
                    <img className='UserHeader-img-kh' src={process.env.PUBLIC_URL+'/img/logo2.jpeg'} />
                      <span onClick={()=>{setSidebarActivator(false)}}> <FontAwesomeIcon icon={faXmark} /></span>
                      <hr/>
                      {user_role==='Admin' && (<>
                      <div style={PageActivator[0]?{backgroundColor:'#007bff', color:'white'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/" onClick={()=>{Handel_ColorOnActive(0)}}> <span style={PageActivator[0]?{color:'white'}:{color:'black'}}> الصندوق  <FontAwesomeIcon icon={faGem} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[1]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/GenerateBilling" onClick={()=>{Handel_ColorOnActive(1)}}> <span style={PageActivator[1]?{color:'white'}:{color:'black'}} > طباعة فاتورة إيراد <FontAwesomeIcon icon={faPrint} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      
                      <div style={PageActivator[13]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/GenerateExpenseBill" onClick={()=>{Handel_ColorOnActive(13)}}> <span style={PageActivator[13]?{color:'white'}:{color:'black'}} >  طباعة فاتورة مصاريف<FontAwesomeIcon icon={faPaintbrush} /></span></Link>                      
                      </li>
                     
                      </div>
                      <hr/>
                      <div style={PageActivator[14]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/StatementOfAccount" onClick={()=>{Handel_ColorOnActive(14)}}> <span style={PageActivator[14]?{color:'white'}:{color:'black'}} > كشف حساب العمال <FontAwesomeIcon icon={faReceipt} /></span></Link>                      
                      </li>
                     
                      </div>
                      <hr/>
                      <div style={PageActivator[2]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/MP" onClick={()=>{Handel_ColorOnActive(2)}}> <span style={PageActivator[2]?{color:'white'}:{color:'black'}} > مشتريات المواد  <FontAwesomeIcon icon={faLayerGroup} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[3]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllDaily" onClick={()=>{Handel_ColorOnActive(3)}}> <span style={PageActivator[3]?{color:'white'}:{color:'black'}} >  الرزق اليومي  <FontAwesomeIcon icon={faAppleWhole} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[4]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/debts" onClick={()=>{Handel_ColorOnActive(4)}}> <span style={PageActivator[4]?{color:'white'}:{color:'black'}} > الديون  <FontAwesomeIcon icon={faHandHoldingDollar} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[5]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllFuel" onClick={()=>{Handel_ColorOnActive(5)}}> <span style={PageActivator[5]?{color:'white'}:{color:'black'}} > المحروقات  <FontAwesomeIcon icon={faGasPump} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[6]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllCars" onClick={()=>{Handel_ColorOnActive(6)}}> <span style={PageActivator[6]?{color:'white'}:{color:'black'}} > أجار السيارات  <FontAwesomeIcon icon={faVanShuttle} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[7]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllEmployees" onClick={()=>{Handel_ColorOnActive(7)}}> <span style={PageActivator[7]?{color:'white'}:{color:'black'}} > العمال والموظفين   <FontAwesomeIcon icon={faPersonDigging} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[8]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllRefrigerators" onClick={()=>{Handel_ColorOnActive(8)}}> <span style={PageActivator[8]?{color:'white'}:{color:'black'}} >  البرادات  <FontAwesomeIcon icon={faTruckMoving} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[9]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllExternalEnvoices" onClick={()=>{Handel_ColorOnActive(9)}}> <span style={PageActivator[9]?{color:'white'}:{color:'black'}} > الفواتير الخارجية   <FontAwesomeIcon icon={faFileInvoiceDollar} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[10]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllCoolingRooms" onClick={()=>{Handel_ColorOnActive(10)}}> <span style={PageActivator[10]?{color:'white'}:{color:'black'}} > غرف التبريد <FontAwesomeIcon icon={faTemperatureArrowDown} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                      <div style={PageActivator[11]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllOtherSales" onClick={()=>{Handel_ColorOnActive(11)}}> <span style={PageActivator[11]?{color:'white'}:{color:'black'}} >  مبيعات أخرى  <FontAwesomeIcon icon={faScaleBalanced} /></span></Link>                      
                      </li>
                      </div>
                      </>)}
                      <hr/>
                      <div style={PageActivator[12]?{backgroundColor:'#007bff'}:{backgroundColor:'white'}}>
                      <li>                     
                      <Link to="/AllBoxesMain" onClick={()=>{Handel_ColorOnActive(12)}}> <span style={PageActivator[12]?{color:'white'}:{color:'black'}} >   الصناديق الحقلية   <FontAwesomeIcon icon={faScrewdriverWrench} /></span></Link>                      
                      </li>
                      </div>
                      <hr/>
                     
                      {/* <li>
                      <a href='/#Map-section-kh' onClick={()=>{Handel_ColorOnActive(2)}}> <span style={{color:ColorOnActive_Sell}}>الخريطة</span></a>
                      </li>
                      <hr/> */}
                
                      {/* <li>
                      <Link to='/' onClick={()=>{Handel_ColorOnActive(3)}}><span style={{color:ColorOnActive_Rent}}>للآجار</span> </Link>
                      </li> */}
                
                
                      {/* <li>
                      <a href='/#contactus-kh' onClick={()=>{Handel_ColorOnActive(4)}}><span style={{color:ColorOnActive_Contact}}>تواصل معنا</span> </a>
                      </li> */}
                
                  
              </ul>
                    </div>
                    <li className='Page-Title-kh'>
                       <span><h5>{probs.title}</h5>  <i className='fa-solid fa-house'></i></span>
                      </li>
                    <div className='UserHeader-Icon' style={SearchActivator?{visibility:'visible'}:{visibility:'hidden'}}  onClick={()=>{navigate('search')}}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                    
                </div>
            </div>
        </>
        
      
    );
}

export default UserHeader;
