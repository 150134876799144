import axios from "axios";
import { ApiURL } from "../../../App";


export const fetchDataAndUpdate = async (userInfo_Now,setAlldata,setloadnig,SetAlert) => {
    

    const storedHash = localStorage.getItem('AllDailyHash');
    
    axios.get(ApiURL+'Daily/GetAllDailies?hash='+storedHash,{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
     }).then((data)=>{
        if(data.data.hash === storedHash)
        {
            const storedData = localStorage.getItem('AllDaily');
            setAlldata(JSON.parse(storedData));
        }
        else
        {
            localStorage.setItem('AllDaily',JSON.stringify(data.data.data) );
            localStorage.setItem('AllDailyHash', data.data.hash );
            setAlldata(data.data.data);
        }
       
        setloadnig(false);
        
      })
      .catch((err)=>{
          setloadnig(false);    
          SetAlert({Activate:true,msg:'لايوجد اتصال بالانترنت، يعمل حاليا بحالة عدم الاتصال!'})  
          const storedData = localStorage.getItem('AllDaily');
          if(storedData!==null)
          {setAlldata(JSON.parse(storedData));    }
                 
        })

};

