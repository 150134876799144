import React from 'react';
import DatePicker from 'react-multi-date-picker';
import arabic from 'react-date-object/locales/arabic_ar';

const DatePickerMain = ({value, setValue}) => {

  
  
 function setvaluehundel(D){
  var date_=new Date(D.year,D.month.index,D.day);
  setValue(date_);
 }
  return (
    <DatePicker
   
      value={value}
      onChange={setvaluehundel}
      onlyMonthPicker={true}
      format="MM/YYYY"
      style={{  padding: '10px', fontSize: '16px',backgroundColor:'#007bff', border:'1px solid #007bff',
        color:'white', textAlign:'center', direction:'rtl'
       }}
       editable={false}
    />
  );
};

export default DatePickerMain;
