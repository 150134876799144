import { faAppleWhole, faBoxesStacked, faBoxOpen, faCalendarDays, faComments, faHandshake, faMoneyBill1Wave, faSackDollar, faScaleBalanced, faTractor, faWeightScale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import Skeleton from 'react-loading-skeleton';
import KhAlert from '../../Components/Alert/KhAlert';
import { NumericFormat } from 'react-number-format';
import DeleteConfirmation from '../../Components/Delete Confirmation component/DeleteConfirmation';

const DetailsRefrigerator = () => {const { setAlldata } = useOutletContext();

const queryParameters = new URLSearchParams(window.location.search);  // to get RefrigeratorId form url
const RefrigeratorId = queryParameters.get("Id"); 

const navigate=useNavigate();


const [data, setData] = useState(
  {
    id: 0,
  totalBoxes: 0,
  totalBalanceCardWeight: 0,
  totalEmptyBoxesWeight: 0,
  totalWeightAfterDiscount_2Percent: 0,
  codeNumber: "string",
  totalSalesPriceOfAll: 0,
  buyerName: "string",
  buyerId: 0,
  date: "2024-08-20T14:00:37.697Z",
  notes: "string",
  refrigeratorDetailsDtos: [
    {
      "repositoryMaterialId": 0,
      "materialName": "string",
      "countOfBoxes": 0,
      "balanceCardWeight": 0,
      "emptyBoxesWeight": 0,
      "weightAfterDiscount_2Percent": 0,
      "salesPriceOfUnit": 0,
      "salesPriceOfAll": 0
    }
  ]
  });
const [loading,setloadnig]=useState(true);
const [alert_,SetAlert]=useState({Activate:false,msg:''});

const userInfo_Now =useContext(UserInfokh);

const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});

function handleDeleteClick(id) {
  setDeleteConfirmation({'active':true,'id':id});
}
const handleClose = () => {
  setDeleteConfirmation({'active':false,'id':0});
};

function handleConfirm() {
  // Perform the delete action here
  DeleteHundel();
  setDeleteConfirmation({'active':false,'id':0});
}

useEffect(()=>{

    const fetchData = async () => {
        setloadnig(true);
        try {
        const res=  await axios.get(ApiURL+'Refrigerator/GetRefrigeratorById?id='+RefrigeratorId,{   
          headers:{
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
          });
          setData(res.data);
            setloadnig(false);     
          }
          catch(err)
          {
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            setloadnig(false);   
         
        }
      
      };
     
        fetchData();
        window.history.replaceState(null,'','/');
    },[]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

    
    async function DeleteHundel()
    {
      setloadnig(true);  
      const Func_Param=ApiURL+`Refrigerator/DeleteRefrigerator?Id=${data.id}`;
      try{
       
        const res=await axios.options(Func_Param,{            
          headers:{
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
      });
   
        if(res.data)
          {
            // Refresh the page
            setAlldata((prevData) => prevData.filter(item => item.id !== data.id));
            navigate('/AllRefrigerators');
           
          }
          else{
            SetAlert({Activate:true,msg:'يوجد خطأ بالبيانات'});
          }
      }
      catch(error_)
      {
       
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
        
      }

      setloadnig(false); 
    } 


return (
    <>
    <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <div className='parent-Dmaterial'>
            <div className='child-Dmaterial' style={{width:'100%', left:'0px'}}>
            {!loading && <> <span className='close-kh' onClick={()=>{navigate('/allRefrigerators')}}> X </span>
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faCalendarDays} /> التاريخ:  </span> {formatDate(data.date)} </div> 
               
               <div className='Details-kh'> <span> <FontAwesomeIcon icon={faHandshake} /> التاجر: </span>  {data.buyerName} </div> 
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faBoxesStacked} /> عدد الطبالي الكلي: </span>  {data.totalBoxes} </div>
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faBoxOpen} />  الوزن الكلي للطبالي  : </span>  {data.totalEmptyBoxesWeight} كغ</div> 

               <div className='Details-kh'> <span><FontAwesomeIcon icon={faWeightScale} />  القائم: </span>  {data.totalBalanceCardWeight} كغ</div>               
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faScaleBalanced} />  صافي الوزن الكلي: </span> <NumericFormat value={data.totalWeightAfterDiscount_2Percent} displayType={'text'} thousandSeparator={true} /> كغ</div> 
               <div className='Details-kh'> <span><FontAwesomeIcon icon={faSackDollar} /> السعر الكلي : </span> <NumericFormat value={data.totalSalesPriceOfAll} displayType={'text'} thousandSeparator={true} />  ل.س</div>  
          
               <fieldset className='Add-fieldset'>
                  <legend>المحتويات</legend>

              <div className='table-container' style={{height:'125px', maxHeight:'235px',padding:'1px'}}> 
              <table className="custom-table" style={{margin:'0'}}>
                <thead style={{fontSize:'12px', textAlign:'center'}}>
                    <tr>
                      <th>النوع</th>
                      <th>عدد الطبالي </th>
                      <th>وزن الطبلية</th>
                      <th>قائم</th>
                      <th>صافي</th>
                      <th>سعر الكيلو</th>
                      <th>السعر الاجمالي</th>
                    </tr>
            </thead>
            <tbody style={{fontSize:'12px', textAlign:'center'}}>
                {data.refrigeratorDetailsDtos.map((row, index) => (
                <tr key={index}>
                    <td>
                       {row.materialName}
                    </td>
                    <td>
                      {row.countOfBoxes}
                    </td>
                    <td>
                      <NumericFormat value={row.emptyBoxesWeight} displayType={'text'} thousandSeparator={true} />
                    </td>
                    <td>
                      <NumericFormat value={row.balanceCardWeight} displayType={'text'} thousandSeparator={true} />
                    </td>
                    <td>
                      <NumericFormat value={row.weightAfterDiscount_2Percent} displayType={'text'} thousandSeparator={true} />
                    </td>
                    <td>
                      <NumericFormat value={row.salesPriceOfUnit} displayType={'text'} thousandSeparator={true} />  ل.س
                    </td>
                    <td>
                    <NumericFormat value={row.salesPriceOfAll} displayType={'text'} thousandSeparator={true} />  ل.س
                    </td>
                </tr>
                ))}
            </tbody>
             </table>
                </div>
                </fieldset>

              <div className='Details-kh'> <span><FontAwesomeIcon icon={faComments} /> ملاحظات: </span><div className='Note-kh'> {data.notes} </div>  </div> 
               <div className='Details-kh'> <button className='btn-Add btn-delete' onClick={()=>{handleDeleteClick()}}>حذف</button> </div>  
               </>}
               {loading && <>
                <div className='Details-kh'> <Skeleton height='10px' width='200px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                <div className='Details-kh'> <Skeleton height='150px'  /></div>
               </>}  
            </div>

            <DeleteConfirmation
        show={showDeleteConfirmation}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
        </div>
        
         
    </>
);
}

export default DetailsRefrigerator;
