import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiURL } from '../App';
import { TailSpin } from 'react-loader-spinner';
import { UserInfokh } from '../Contexts/UserContext';
import KhAlert from '../Components/Alert/KhAlert';

const Login = () => {
    const [Email,setEmail]=useState('');
    const [Password,setPassword]=useState('');
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,setflag]=useState(false);
    const [loading, setloading] = useState(false); 
    const userInfo_Now =useContext(UserInfokh);
    const navigate =useNavigate();

    async function Submit(e){
    e.preventDefault();
        setflag(true);
      
        if(Email.length>1  && Password.length>1 )
          {
            // sending data
            try{
              setflag(false);
              setloading(true);
             
              let res=await axios.post(ApiURL+'Account/Login',{
              email: Email,
              password:Password          
            });
           
            if(res.status===200)
              {
                document.cookie="token="+res.data.token;
                document.cookie="email="+res.data.email;
                document.cookie="password="+Password;
                document.cookie="role="+res.data.role;
                 
               userInfo_Now.setAuth({'token':res.data.token,'email':res.data.email,'password':Password,'role':res.data.role});
             
               //setSpinner(false);
               //args_.funloading(false);
               window.history.replaceState(null,'','/');
               
               if(res.data.role==='Admin'){
                navigate('/');
               }
               else
               {
                navigate('/AllBoxesMain');
               }
                
             
              }
           
          }
          catch(error_)
          {
           
            setflag(true);
            if(error_.response.status===400)
              {
                SetAlert({Activate:true,msg:'اسم المستخدم أو كلمة المرور خطأ'});
           
              }
              else{
                SetAlert({Activate:true,msg:'تأكد كم الاتصال بالأنترنت'});
              }
             
          }
          setloading(false);
          
          }
        
      }

    return (
        <>
    <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />          
   <div className='login-container'>
  
   {!loading && <> <div className="container">
       <div className="row">
        <div className="col-lg-3 col-md-2" />
          <div className="col-lg-6 col-md-8 login-box">
            <div className="col-lg-12 login-key">
                <i className="fa fa-key" aria-hidden="true" />
            </div>
            <div className="col-lg-12 login-title">
                ADMIN PANEL
            </div>
            <div className="col-lg-12 login-form">
                <div className="col-lg-12 login-form">
                <form>
                    <div className="form-group">
                    <label className="form-control-label">EMAIL</label>
                    <input type="text" 
                    className="form-control"                    
                    value={Email}
                    onChange={(e)=>{setEmail(e.target.value)}} 
                    />
                     {Email.length<2 && flag && (  <p className="validationReg">الإيميل مطلوب *</p>)}
                    </div>
                    <div className="form-group">
                    <label className="form-control-label">PASSWORD</label>
                    <input type="password" 
                        className="form-control"                     
                     value={Password}
                     onChange={(e)=>{setPassword(e.target.value)}}
                     />
                     {Password.length<1 &&flag&& ( <p className="validationReg"> قم بإدخال كلمة المرور*</p>)}
                    </div>
                    <div className="col-lg-12 loginbttm">
                    <div className="col-lg-6 login-btm login-text">
                        {/* Error Message */}
                    </div>
                    <div className="col-lg-6 login-btm login-button">
                        <button onClick={(e)=>{Submit(e)}} className="btn btn-outline-primary">LOGIN</button>
                    </div>
                    </div>
                </form>
                </div>
            </div> 
            <div className="col-lg-3 col-md-2" />
            </div>
        
        </div>

 
</div> </>}
{loading && 
            <div style={{textAlign:'center'}}>
                <TailSpin
                    wrapperClass={'loading-kh-new'}
                    height="90vh"
                    width="60"
                    color="#ffffff"
                    ariaLabel="loading"
                />
            </div>
}
</div>        

        </>
    );
}

export default Login;
