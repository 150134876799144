import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';


const AddRepository = () => {

  const queryParameters = new URLSearchParams(window.location.search);  // to get categoryId form url
  const categoryId = queryParameters.get("catId"); 


  const userInfo_Now =useContext(UserInfokh);
  const [alert_,SetAlert]=useState({Activate:false,msg:''});
  const [flag,Setflag]=useState(false);

  const [Matirialoptions, setMatirialoptions] = useState([]);
  const [suppliersoptions, setsuppliersoptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
                
      axios.get(ApiURL +'RepositoryMaterials/GetAllByCategoryId?catId='+categoryId,{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
        }).then((data)=>{
          const Material_holder=[];
          data.data.materials.map((item)=>{
            Material_holder.push({
              value:item.name,
              label:item.name,
              defaultPrice:item.defaultPrice})
            
          });
          setMatirialoptions(Material_holder);
          
          const Supplier_holder=[];
          data.data.suppliers.map((item)=>{
            Supplier_holder.push({ value:item.supplierName,
              label:item.supplierName})
            
          })
          setsuppliersoptions(Supplier_holder);
          
        })
        .catch((err)=>{
          SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
          })
    
    };

    fetchData();
  }, []);

  const animatedComponents = makeAnimated();
  const [formData, setFormData] = useState({
    categoryId:categoryId,
    repositoryMaterialId:0,
    repositoryMaterialName: '',
    amount: '',
    buyPriceOfUnit: '',
    supplierName: '',
    date: '',
    notes: ''
  });
 
  const handleCreateOptionsupplier = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setsuppliersoptions((prevOptions) => [...prevOptions, newOption]);
  
  };
  const handleCreateOptionMaterial = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setMatirialoptions((prevOptions) => [...prevOptions, newOption]);
  
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    
  };
  function RestValues(){
   
    setFormData({
      categoryId:categoryId,
      repositoryMaterialId: formData.repositoryMaterialId,
      repositoryMaterialName: formData.repositoryMaterialName,
      amount: '',
      buyPriceOfUnit: '',
      supplierName: formData.supplierName,
      date: '',
      notes: ''
    });
  }

  const handleSubmit = async (e) => {
    Setflag(true);
    if(!(formData.repositoryMaterialName.length<2||formData.supplierName.length<2 || formData.amount<0.01 || formData.buyPriceOfUnit<0.01||formData.date.length<1))
    {
      try {
      const res = await axios.post(ApiURL+'RepositoryInOut/AddRepository', formData, {
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
           'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
       
      });
      RestValues();
      SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
      
    } catch (error) {      
      SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
     
    }
    }
  };
//#region OnWheel Prevent Change
const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}
//#endregion
  return (
    <div> 
      <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
      <UserHeader title='إضافة مادة' SearchActivator={false}/>
      {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
      <div className='form-addRepo' >
            <div className="mb-3">
                <label className="form-label">
                المادة:
                </label>
                <CreatableSelect
                    id='Select_repositoryMaterialId_'
                    components={animatedComponents}
                    options={Matirialoptions}
                    defaultValue={formData.repositoryMaterialName}
                    onChange={(selectedOption) => handleChange('repositoryMaterialName', selectedOption.value)}  
                    onCreateOption={handleCreateOptionMaterial}
                    isSearchable
                    placeholder=" اختر مادة أو أضف مادة جديدة..."                    
                  />
               {formData.repositoryMaterialName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار مادة</p>)}
            </div>

            <div className="mb-3">
                <label className="form-label">
                المصدر:
                </label>
                <CreatableSelect
                    id='Select_supplierName_'
                    components={animatedComponents}
                    options={suppliersoptions}
                    defaultValue={formData.supplierName}
                    onChange={(selectedOption) => handleChange('supplierName', selectedOption.value)}
                    onCreateOption={handleCreateOptionsupplier}
                    isSearchable
                    placeholder="اختر مصدر أو أضف مصدر جديد..."
                  />
               {formData.supplierName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار مصدر</p>)}
            </div>
            
            <div className="mb-3"> 
              <label className="form-label">
                الكمية
              </label>
              <input
                className="form-control"
                type="number"
                name="amount"
                value={formData.amount}
                onChange={(e)=>{handleChange('amount',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.amount<0.01 &&flag&& ( <p className="validationReg"> *الرجاء تحديد الكمية</p>)}
            </div>

            
            <div className="mb-3"> 
              <label className="form-label">
              السعر الإفرادي
              </label>
              <input
                className="form-control"
                type="number"
                name="buyPriceOfUnit"
                value={formData.buyPriceOfUnit}
                onChange={(e)=>{handleChange('buyPriceOfUnit',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.buyPriceOfUnit<0.01 &&flag&& ( <p className="validationReg"> *الرجاء تحديد السعر</p>)}
            </div>
           
            <div className="mb-3">
              <label className="form-label">
                التاريخ
              </label>
              <input
                className="form-control"
                type="date"
                name="date"
                value={formData.date}
                onChange={(e)=>{handleChange('date',e.target.value)}}
              />
               {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
            </div>
            <div className="mb-3">
                <label className="form-label">
                ملاحظات
                </label>
                <textarea 
                type='text'
                className="form-control"
                name="notes" 
                value={formData.notes} 
                onChange={(e)=>{handleChange('notes',e.target.value)}}
                placeholder={"إضافة ملاحظات..."}               
                />
              
            </div>
       
        <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
        </div>
      {/* </form> */}
    </div>
    
  );
};

export default AddRepository;
