import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../src/Components/DatePicker/DatePickerCss.css'
import '../src/Components/Transactions/TransactionCss.css'
import '../src/Pages/Material purchases/MaterialPurchasesCss.css'
import 'react-loading-skeleton/dist/skeleton.css';
import '../src/Components/AddButton/AddButtonCSS.css'
import '../src/Components/Search/SearchCss.css'
import '../src/Pages/Financial Entitlements/FinancialEntitlementsCss.css'
import '../src/Pages/Financial Payments/FinancialPaymentsCss.css'
import '../src/Pages/Employee/EmployeeCss.css'
import '../src/Components/Delete Confirmation component/DeleteConfirmationCss.css'
import '../src/Pages/MainPage/MainPageCss.css'
import '../src/Components/Main Page/PieCss.css'
import '../src/Components/AddWindow/AddWindowCss.css'
import '../src/Pages/Billing/BillingCss.css'
import '../src/LogIn/LoginCss.css'
import '../src/Components/Refrigerator/RefrigeratorsCSS.css';
import '../src/Box Section/BoxSectionCss.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
