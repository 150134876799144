import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';
import GroupsR from '../../Components/Refrigerator/Add Component/GroupsR';
import { useNavigate } from 'react-router-dom';

const AddRefrigerator = () => {const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
    const [RemoveActivate,setRemoveActivate]=useState(false);
  
    const [Matirialoptions, setMatirialoptions] = useState([]);
    const [BuyerNamesoptions, setBuyerNamesoptions] = useState([]);

    const navigate=useNavigate();
  
    useEffect(() => {
      const fetchData = async () => {
                  
        axios.get(ApiURL +'RepositoryMaterials/GetAllByCategoryId?catId='+1,{   
          headers:{
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
          }).then((data)=>{
            const Material_holder=[];
            data.data.materials.map((item)=>{
              Material_holder.push({
                value:item.id,
                label:item.name,
                defaultPrice:item.defaultPrice})
              
            });
            setMatirialoptions(Material_holder);
            
            const buyers_holder=[];
            data.data.buyers.map((item)=>{
                buyers_holder.push({ value:item.name,
                label:item.name})
              
            })
            setBuyerNamesoptions(buyers_holder);
            
          })
          .catch((err)=>{
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            })
      
      };
  
      fetchData();
    }, []);
  
    const animatedComponents = makeAnimated();
    const [subformData, setsubformData] = useState({
      repositoryMaterialId: null,
      totalBoxes: '',
      balanceCardWeight: '',
      emptyBoxesWeight: 2,
      salesPriceOfUnit: '',
  });
    
    const [formData, setFormData] = useState({
        details:[subformData],
        buyerName: '',
        date: new Date().toISOString().split('T')[0],
        notes: ''
    });
   
    const handleCreateOptionsupplier = (inputValue) => {
      const newOption = { label: inputValue, value: inputValue };
      setBuyerNamesoptions((prevOptions) => [...prevOptions, newOption]);
    
    };
  
    const handleChange = (name, value) => {
      setFormData({
        ...formData,
        [name]: value
      });
      
    };
    function RestValues(){
      Setflag(false);
     
      setFormData({
        details:[subformData],        
        buyerName: formData.buyerName,
        date: new Date().toISOString().split('T')[0],
        notes: ''
        });    
    }
  
    const handleSubmit = async (e) => {
      Setflag(true);
      if(!(formData.buyerName.length<2 ||formData.date.length<1))
      {
        
          const hasInvalidDetail = formData.details.some(element => {
            return (
              element.repositoryMaterialId<1||
              element.totalBoxes < 0.01 ||
              element.balanceCardWeight < 0.01 ||
              element.emptyBoxesWeight < 0.01 ||
              element.salesPriceOfUnit.length < 1
            );
          });

          if (hasInvalidDetail) {
            return; // الخروج من `handleSubmit` بالكامل إذا كان هناك أي عنصر غير صالح
          }
      
        try {
        const res = await axios.post(ApiURL+'Refrigerator/AddNewRefrigerator', formData, {
          headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
             'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
         
        });
        navigate('/AllRefrigerators');
        //SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
        
      } catch (error) {     
        
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
       
      }
      }
    };

    function handleAddGroup()
    {
      const helper_subformData=[...formData.details];
      helper_subformData.push(subformData);

      setFormData({
        ...formData,
        'details':helper_subformData
      });
      setRemoveActivate(true);
    }
    function handleRemoveGroup()
    {
      
      const helper_subformData=[...formData.details];
      helper_subformData.pop();
     
      setFormData({
        ...formData,
        'details':helper_subformData
      });
      if(helper_subformData.length<2)
      {
        setRemoveActivate(false);
      }
    }
    
    //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
      
        // Prevent the page/container scrolling
        e.stopPropagation()
      
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      //#endregion
    return (
      <div> 
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <UserHeader title='بيع براد ' SearchActivator={false}/>
        {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
        <div className='form-addRepo' >

             <div className="mb-3">
                <label className="form-label">
                  التاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={(e)=>{handleChange('date',e.target.value)}}
                />
                 {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
              </div>

              <div className="mb-3">
                  <label className="form-label">
                  التاجر:
                  </label>
                  <CreatableSelect
                      id='Select_supplierName_'
                      components={animatedComponents}
                      options={BuyerNamesoptions}
                      defaultValue={formData.buyerName}
                      onChange={(selectedOption) => handleChange('buyerName', selectedOption.value)}
                      onCreateOption={handleCreateOptionsupplier}
                      isSearchable
                      placeholder="اختر تاجر أو أضف تاجر جديد..."
                    />
                 {formData.buyerName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار تاجر</p>)}
              </div>
              <fieldset className='Add-fieldset'>
                  <legend>المحتويات:</legend>

                { formData.details.map((item,index)=>
                  <GroupsR key={index} Matirialoptions={Matirialoptions} setFormData={setFormData}
                  formData={formData}
                  subformData={item}
                  indexOfsubFdata={index}
                  flag={flag} />
                )
                }
                <button className='Add-fieldset-btn-add' onClick={handleAddGroup}>إضافة نوع</button>
               {RemoveActivate && <button className='Add-fieldset-btn-remove' onClick={handleRemoveGroup}>حذف نوع</button>}
             </fieldset>
              <div className="mb-3">
                  <label className="form-label">
                  ملاحظات
                  </label>
                  <textarea 
                  type='text'
                  className="form-control"
                  name="notes" 
                  value={formData.notes} 
                  onChange={(e)=>{handleChange('notes',e.target.value)}}
                  placeholder={"إضافة ملاحظات..."}               
                  />
                
              </div>
         
          <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
          </div>
        {/* </form> */}
      </div>
      
    );
  };

export default AddRefrigerator;
