import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const Operations = (props) => {

    const navigate=useNavigate();
  
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };

    return (
        <div className={`Transaction-kh ${props.props.direction === "خارج" ? "Outcoming-kh" : "Incoming-kh"}`}>
          
        <div className="parent-kh" onClick={()=>{navigate('BoxOfUSerDetailed?id='+props.props.id)}}>
           <div> <h4>{formatDate(props.props.date)}</h4></div>
           <div> <h5>حالة الصناديق : <span style={props.props.direction==="خارج"?{color:'red'}:{color:'green'}} >  {props.props.direction}</span> </h5></div>
           <div> <h5></h5></div>
           <div> <h4>العدد :  {props.props.count} </h4></div>
           
       </div>
       
      
   </div>
    );
}

export default Operations;
