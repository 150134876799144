import React, { useContext, useEffect, useState } from 'react';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import NotFound from '../../Components/NotFound/NotFound';
import Debts from '../../Components/Financial Entitlements/Debts';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';
import { NumericFormat } from 'react-number-format';
import { Outlet } from 'react-router-dom';

const FinancialEntitlements = () => {

    const userInfo_Now =useContext(UserInfokh);
    const [Alldata, setAlldata] = useState([]);
    const [loading,setloadnig]=useState(true);
    const [SumAll, setSumAll] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setloadnig(true);
           
          axios.get(ApiURL+'FinancialEntitlements/GetAllFinancialEntitlements',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              setAlldata(data.data);
              var sum_=0;
              data.data.map((item)=>{
                item.remainder=-item.remainder;
                item.totalAmount=-item.totalAmount;
                item.totalPayments=-item.totalPayments;
                sum_ +=item.remainder;
              })
              setloadnig(false);
              setSumAll(sum_.toFixed(2));
            })
            .catch((err)=>{
                setloadnig(false);
            //   setِ_ِAlert_msg(err);
            //   setِAlert(true); 
                       
              })
        
        };
    
        fetchData();
      }, []);


    return (
        <>
        <Outlet context={{ Alldata }}/>   
         <UserHeader title='الديون' SearchActivator={false} Active={4}/>
              
         {!loading && Alldata.length===0 && <NotFound />}
         {!loading &&  <div className='GetAll-pages-kh'>
            <div className='MaterialPurchases-Sum' ><h6><b>مجموع الباقي: </b><span style={SumAll<0?{color:'red'}:{color:'green'}} ><NumericFormat value={SumAll} displayType={'text'} thousandSeparator={true} />  ل.س</span></h6></div>
         
           {Alldata.map((item)=>
                <Debts key={item.id} props={item} />                
            )}
           <br/><br/><br/>
        </div>}
       
        
        {loading && <Skeleton_kh />}   
       
    </>
    );
}

export default FinancialEntitlements;
