
import { useContext, useState } from 'react';
import KhAlert from '../../../Components/Alert/KhAlert';
import { UserInfokh } from '../../../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiURL } from '../../../App';

const AddCheckEmpolyee = () => {

  const queryParameters = new URLSearchParams(window.location.search);  // to get employeeId form url
    const employeeId = queryParameters.get("Id"); 
  
    const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
    const navigate =useNavigate();
    const [formData, setFormData] = useState({
        employeeId: employeeId,
        girlsCount: 0,
        menCount  : 0,
        normJobHCount: 0,
        addJobHCount: 0,
        reward:0,
        discount:0,
        paidWage:0,
        date: new Date().toISOString().split('T')[0],
        notes: ''
      });
   
      const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value
        });        
      };

      const handleSubmit = async (e) => {
        Setflag(true);
        if(!(formData.date.length<1 ||formData.employeeId.length<1||formData.girlsCount.length<1
          ||formData.menCount.length<1 ||formData.normJobHCount.length<1||formData.addJobHCount.length<1
          ||formData.reward.length<1 ||formData.discount.length<1 ||formData.paidWage.length<1
        ))
        {
          try {
          const res = await axios.post(ApiURL+'Employee/AddNewDailyCheck', formData, {
            headers: {
              'Accept': 'text/plain',
              'Content-Type': 'application/json',
               'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
           
          });
          navigate('/AllEmployees');
          navigate(0)
          
        } catch (error) {      
          SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
         
        }
        }
      };

      //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
      // Prevent the input value change
      e.target.blur()
    
      // Prevent the page/container scrolling
      e.stopPropagation()
    
      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus()
      }, 0)
    }
    //#endregion

    return (
        <>
         <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <div className='parent-Dmaterial'>
                <div className='child-Dmaterial'>
                <div className="mb-3">
              <label className="form-label">
                التاريخ
              </label>
              <input
                className="form-control"
                type="date"
                name="date"
                value={formData.date}
                onChange={(e)=>{handleChange('date',e.target.value)}}
              />
               {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
            </div>

                <div className="mb-3"> 
              <label className="form-label">
              عدد العمال الشباب  
              </label>
              <input
                className="form-control"
                type='number'
                name="menCount"
                value={formData.menCount}
                onChange={(e)=>{handleChange('menCount',e.target.value)}}
                min={0}
							/>
              {formData.menCount.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال عدد الشباب </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
              عدد العاملات   
              </label>
              <input
                className="form-control"
                type="number"
                name="girlsCount"
                value={formData.girlsCount}
                onChange={(e)=>{handleChange('girlsCount',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
            />
               {formData.girlsCount.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
               </div>
           
            <div className="mb-3"> 
              <label className="form-label">
             عدد ساعات العمل الطبيعي
              </label>
              <input
                className="form-control"
                type="number"
                name="normJobHCount"
                value={formData.normJobHCount}
                onChange={(e)=>{handleChange('normJobHCount',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
              />
             {formData.normJobHCount.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
                
            <div className="mb-3"> 
              <label className="form-label">
             عدد ساعات العمل اإضافي
              </label>
              <input
                className="form-control"
                type="number"
                name="addJobHCount"
                value={formData.addJobHCount}
                onChange={(e)=>{handleChange('addJobHCount',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
              />
             {formData.addJobHCount.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
             أجار سرفيس
              </label>
              <input
                className="form-control"
                type="number"
                name="reward"
                value={formData.reward}
                onChange={(e)=>{handleChange('reward',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
              />
             {formData.reward.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
              الحسم
              </label>
              <input
                className="form-control"
                type="number"
                name="discount"
                value={formData.discount}
                onChange={(e)=>{handleChange('discount',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
              />
             {formData.discount.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
            <div className="mb-3"> 
              <label className="form-label">
              دفع الأجر حاليا
              </label>
              <input
                className="form-control"
                type="number"
                name="paidWage"
                value={formData.paidWage}
                onChange={(e)=>{handleChange('paidWage',e.target.value)}}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
              />
             {formData.paidWage.length<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال قيمة هنا   </p>)}
            </div>
           
           
            <div className="mb-3">
                <label className="form-label">
                ملاحظات
                </label>
                <textarea 
                type='text'
                className="form-control"
                name="notes" 
                value={formData.notes} 
                onChange={(e)=>{handleChange('notes',e.target.value)}}
                placeholder={"إضافة ملاحظات..."}               
                />
              
            </div>
       
        <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
                </div>
            </div>
            
        </>
    );
}

export default AddCheckEmpolyee;
