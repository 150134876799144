import React, { useEffect, useState } from 'react';
import '../Alert/KhAlert.css';

const KhAlert = ({Kh_Active,msg,func_setactive}) => {

    const [AlertActive,setAlertActive]=useState('none');
    useEffect(()=>{

        if(Kh_Active)
            setAlertActive('block');
        else
        {
            setAlertActive('none');
            func_setactive(false);
        }

    },[Kh_Active])

    return (
        <div id="alert_Kh_div" className="alert_container" style={{display:AlertActive}}>

            <div className="Alert_Kh">
            
                <div id="Alert_content" className="Alert_content">
                    <div className="Alert_content-div">
                        <span>
                            {msg}
                        </span>
                    </div>
                    

                </div>
                <div className="kh-alert-btn" onClick={()=>{func_setactive({Activate:false,msg:''});setAlertActive('none');}} >
                    <span id='Alert_Ok'>إغلاق</span>
                </div>
            </div>
        </div>
    );
}

export default KhAlert;
