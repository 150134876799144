import React, { useState } from 'react';
import DatePickerMain from './DatePickerMain';
import { faAngleLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DatePicker = ({SearchFlage,value, setValue}) => {
        
    function addSubOneMonth(month_){
        setValue(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setMonth(newDate.getMonth() + month_);
            return newDate;
        });
    }
    return (
        <div>
            
            <div className="date-picker-bar">
                {SearchFlage && <div className='btn'>بحث</div>}
                {!SearchFlage && <><div className='btn' onClick={()=>addSubOneMonth(-1)}> <FontAwesomeIcon icon={faAngleLeft} /> </div> 
                <DatePickerMain value={value} setValue={setValue} />
                <div className='btn' onClick={()=>addSubOneMonth(1)}> <FontAwesomeIcon icon={faChevronRight} /> </div> 
                </> }
            </div>
            
            
        </div>
    );
}

export default DatePicker;
