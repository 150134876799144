import React from 'react';
import DataListAllBoxes from './DataListAllBoxes';



const AllBoxesAsTypeCom = ({title,data,isInUserPage}) => {
   
    return (
        <div className='CardWithPie-Parent'>
            <div><h3>{title}</h3></div>
            <div className='table-Boxes-parent'>
            <table className="custom-table-Boxes">
                <thead>
                    <tr>
                    <th>{isInUserPage? 'متبقي لديه': 'الموجود'}</th>
                    <th>خارج</th>
                    <th>داخل</th>
                    <th>النوع</th>
                    </tr>
            </thead>
            <tbody>
                 {data.map((row, index) => ( 
                <tr key={index}>
                    <td>
                    <div style={isInUserPage?{color:'red'}:{color:'green'}}> {row.remainder} </div>
                    </td>
                    <td>
                    <div style={{color:'red'}}> {row.totalOut}</div>
                    </td>
                    <td>
                    <div style={{color:'green'}}> {row.totalIn} </div>
                    </td>
                    <td>
                    <div> {row.color} </div>
                    </td>

                </tr>
                ))}
                
            </tbody>
             </table>
               
            </div>
        </div>
    );
}

export default AllBoxesAsTypeCom;