import React from 'react';
import BarChartKh from './BarChartKh';

const CardWithBar = (props) => {
    // const redData = [
    //     { date: '08/03', value: 0 },
    //     { date: '08/04', value: 1000000 },
    //     { date: '08/05', value: 0 },
    //     { date: '08/06', value: 2000000 },
    //     { date: '08/07', value: 0 },
    //     { date: '08/08', value: 50 },
    //     { date: '08/09', value: 0 },
    //   ];
    
      // const greenData = [
      //   { date: '08/03', value: 100000 },
      //   { date: '08/04', value: 200000 },
      //   { date: '08/05', value: 0 },
      //   { date: '08/06', value: 0 },
      //   { date: '08/07', value: 0 },
      //   { date: '08/08', value: 10000 },
      //   { date: '08/09', value: 0 },
      // ];
    return (
        <>
         <div className='CardWithPie-Parent'>
            <div><h3>{props.title}</h3></div>
            <div className='CardWithBar-SubParent'>
              <BarChartKh data={props.greenData} color="#50ad4d" />
              <BarChartKh data={props.redData} color="#f24334" />
              </div>
              
              </div>
             
        </>
    );
}

export default CardWithBar;
