import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import KhAlert from '../../Components/Alert/KhAlert';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';
import AllBoxesCom from '../Components/AllBoxes/AllBoxesCom';
import AllBoxesAsTypeCom from '../Components/AllBoxes/AllBoxesAsTypeCom';
import BoxesWidrawnByFar from '../Components/AllBoxes/BoxesWidrawnByFar';
import FloatBtn from '../Components/FloatButton/FloatBtn';
import SumOfBoxes from '../Components/AllBoxes/SumOfBoxes';

const AllBoxesMain = () => {

    const userInfo_Now =useContext(UserInfokh);
    const [Alldata, setAlldata] = useState(
        {
            topOfThePage: {
                totalIn: 0,
                totalOut: 0,
                current: 0
              },
              middleOfThePage: [
              ],
              bottomOfThePage: [
              ]
        }
    );
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
   
    const [Total,setToltal]=useState({
      TotalIn:0,
      TotalOut:0,
      TotalNotIn:0
    });

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const year = date.getFullYear();
      
          return `${day}/${month}`;
      };

  useEffect(() => {
    const fetchData = async () => {
        setloadnig(true);
      
      axios.get(ApiURL+'BoxesField/GetBoFMainPage',{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
        }).then((data)=>{
            // console.log(data.data)
          setAlldata(data.data);
          var Tin=0;
          var Tout=0;
          var TNotin=0;
          data.data.bottomOfThePage.map((row, index)=>{
            if(row.userName!='المشمعة')
            {
              Tin +=row.totalIn;
              Tout +=row.totalOut;
              TNotin +=row.remainder
            }

          })
          setToltal({
            TotalIn:Tin,
            TotalOut:Tout,
            TotalNotIn:TNotin
          })
         
          setloadnig(false);
          
        })
        .catch((err)=>{
            //setloadnig(false);
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                   
          })
    
    };
  
    fetchData();
  }, []);




    return (
        <>
            
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <UserHeader title='الصناديق الحقلية' SearchActivator={false} Active={12}/>
            <div className='background-kh'></div>
            <div id='scrollableDiv' style={{ height:'100vh',overflow: 'auto',padding: '5px'}}>
                <AllBoxesCom title='جميع الصناديق' data={Alldata} />
                <AllBoxesAsTypeCom title='الصناديق حسب النوع' data={Alldata.middleOfThePage} />
                <BoxesWidrawnByFar title='التفاصيل' data={Alldata} />
                <SumOfBoxes title='مجموع الصناديق' data={Total} />
            <br/> 
            <br/> 
            <br/> 
            
            </div>
            {loading && <Skeleton_kh />} 
            <FloatBtn />
        </>
    );
}

export default AllBoxesMain;
