import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';

const AddExternalEnvoices = () => {const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
  
    const [Matirialoptions, setMatirialoptions] = useState([]);
    const [BuyerNamesoptions, setBuyerNamesoptions] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
                  
        axios.get(ApiURL +'RepositoryMaterials/GetAllByCategoryId?catId='+1,{   
          headers:{
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
          }).then((data)=>{
            const Material_holder=[];
            data.data.materials.map((item)=>{
              Material_holder.push({
                value:item.id,
                label:item.name,
                defaultPrice:item.defaultPrice})
              
            });
            setMatirialoptions(Material_holder);
            
            const buyers_holder=[];
            data.data.buyers.map((item)=>{
                buyers_holder.push({ value:item.name,
                label:item.name})
              
            })
            setBuyerNamesoptions(buyers_holder);
            
          })
          .catch((err)=>{
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            })
      
      };
  
      fetchData();
    }, []);
  
    const animatedComponents = makeAnimated();
    const [formData, setFormData] = useState({
        repositoryMaterialId: null,
        totalBoxes: '',
        weight: '',
        salesPriceOfUnit: '',
        buyerName: '',
        date: new Date().toISOString().split('T')[0],
        notes: ''
    });
   
    const handleCreateOptionsupplier = (inputValue) => {
      const newOption = { label: inputValue, value: inputValue };
      setBuyerNamesoptions((prevOptions) => [...prevOptions, newOption]);
    
    };
  
    const handleChange = (name, value) => {
      setFormData({
        ...formData,
        [name]: value
      });
      
    };
    function RestValues(){
      Setflag(false);
      setFormData({
        repositoryMaterialId:''+ formData.repositoryMaterialId,
        totalBoxes: 0,
        weight: 0,
        salesPriceOfUnit: '',
        buyerName: formData.buyerName,
        date: new Date().toISOString().split('T')[0],
        notes: ''
        });    
    }
  
    const handleSubmit = async (e) => {
      Setflag(true);
      if(!(formData.repositoryMaterialId<1||formData.buyerName.length<2 ||formData.totalBoxes<1 
        || formData.weight<0.01|| formData.salesPriceOfUnit.length<1||formData.date.length<1))
      {
        try {
        const res = await axios.post(ApiURL+'ExternalEnvoices/AddNewExternalEnvoices', formData, {
          headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
             'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
         
        });
        RestValues();
        SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
        
      } catch (error) {      
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
       
      }
      }
    };
  
    //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
      
        // Prevent the page/container scrolling
        e.stopPropagation()
      
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      //#endregion
    return (
      <div> 
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
        <UserHeader title='فواتير خارجية ' SearchActivator={false}/>
        {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
        <div className='form-addRepo' >
              <div className="mb-3">
                  <label className="form-label">
                  المادة:
                  </label>
                  <Select
                      id='Select_repositoryMaterialId_'
                      components={animatedComponents}
                      options={Matirialoptions}
                      defaultValue={formData.repositoryMaterialId}
                      onChange={(selectedOption) => handleChange('repositoryMaterialId', selectedOption===null?0:selectedOption.value)}  
                      isClearable
                      isSearchable
                      placeholder="اختر فاكهة..."
                      
                    />
                 {formData.repositoryMaterialId<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار فاكهة</p>)}
              </div>
  
              <div className="mb-3">
                  <label className="form-label">
                  التاجر:
                  </label>
                  <CreatableSelect
                      id='Select_supplierName_'
                      components={animatedComponents}
                      options={BuyerNamesoptions}
                      defaultValue={formData.buyerName}
                      onChange={(selectedOption) => handleChange('buyerName', selectedOption.value)}
                      onCreateOption={handleCreateOptionsupplier}
                      isSearchable
                      placeholder="اختر تاجر أو أضف تاجر جديد..."
                    />
                 {formData.buyerName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار تاجر</p>)}
              </div>
              
              
              <div className="mb-3"> 
                <label className="form-label">
                  عدد الصناديق
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="totalBoxes"
                  value={formData.totalBoxes}
                  onChange={(e)=>{handleChange('totalBoxes',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                              />
                {formData.totalBoxes<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال عدد الصناديق </p>)}
              </div>
              <div className="mb-3"> 
                <label className="form-label">
                  الوزن الكلي
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={(e)=>{handleChange('weight',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                              />
                {formData.weight<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال الوزن   </p>)}
              </div>
             
             
              <div className="mb-3"> 
                <label className="form-label">
                سعر الكيلو الواحد 
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="salesPriceOfUnit"
                  value={formData.salesPriceOfUnit}
                  onChange={(e)=>{handleChange('salesPriceOfUnit',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                  />
                {formData.salesPriceOfUnit.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد السعر</p>)}
              </div>
             
              <div className="mb-3">
                <label className="form-label">
                  التاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={(e)=>{handleChange('date',e.target.value)}}
                />
                 {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
              </div>
              <div className="mb-3">
                  <label className="form-label">
                  ملاحظات
                  </label>
                  <textarea 
                  type='text'
                  className="form-control"
                  name="notes" 
                  value={formData.notes} 
                  onChange={(e)=>{handleChange('notes',e.target.value)}}
                  placeholder={"إضافة ملاحظات..."}               
                  />
                
              </div>
         
          <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
          </div>
        {/* </form> */}
      </div>
      
    );
  };


export default AddExternalEnvoices;
