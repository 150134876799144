import React from 'react';
import DataListAllBoxes from './DataListAllBoxes';



const SumOfBoxes = ({title,data}) => {
    const data_ = [
        { name: 'إجمالي المسحوب', value: data.TotalOut },
        { name: 'إجمالي المرتجع', value: - (data.TotalIn)},
        { name: 'إجمالي غير المرتجع', value: data.TotalNotIn }
      ];
    return (
        <div className='CardWithPie-Parent'>
            <div><h3>{title}</h3></div>
            <div >
                <div>
                <DataListAllBoxes data={data_}/>
                </div>
               
            </div>
        </div>
    );
}

export default SumOfBoxes;