import axios from 'axios';
import Select from 'react-select';
import React, { useContext, useEffect, useState } from 'react';
import { ApiURL } from '../../App';
import { UserInfokh } from '../../Contexts/UserContext';
import makeAnimated from 'react-select/animated';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { StaticInfo } from '../../Contexts/StaticDataContext';

const Search = () => {

  
    // this state from Outlet
    const { formDataSearch,setformDataSearch,SearchMaterialActivator,supplierOfFarmsNameActive} = useOutletContext();
    const activator = SearchMaterialActivator ?? true;
    const supplierOfFarmsNameActive_ = supplierOfFarmsNameActive ?? false;

    const userInfo_Now =useContext(UserInfokh);
    const staticinfo = useContext(StaticInfo);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
  
    //const [Matirialoptions, setMatirialoptions] = useState([]);
   // const [suppliersoptions, setsuppliersoptions] = useState([]);
    const animatedComponents = makeAnimated();
   

    const navigate=useNavigate();

     
      const handleChange = (name,selectedOption) => {
      
          if(name==='supplier')
          {
            staticinfo.setStaticDataInfo({
              ...staticinfo.StaticDataInfo,
              'selectedsupplieroption':selectedOption,
            });
            
              setformDataSearch({
                ...formDataSearch,
                'supplierName': selectedOption!==null?selectedOption.value:''
              });
          }
          else if(name==='supplierOfFarms')
            {
              staticinfo.setStaticDataInfo({
                ...staticinfo.StaticDataInfo,
                'selectedsupplierOfFarmsoption':selectedOption,
              });
              
                setformDataSearch({
                  ...formDataSearch,
                  'supplierOfFarmsName': selectedOption!==null?selectedOption.value:''
                });
            }
          else if(name==='Material')
          {
            staticinfo.setStaticDataInfo({
              ...staticinfo.StaticDataInfo,
              'selectedMatirialoption':selectedOption,
            });
           
              setformDataSearch({
                ...formDataSearch,
                'repositoryMaterialId': selectedOption!==null?selectedOption.value:0
              });
          }   
          else{
            setformDataSearch({
              ...formDataSearch,
              [name]: selectedOption
            });
          }    
      };
      function RestValues(){  
        
        staticinfo.setStaticDataInfo({
          ...staticinfo.StaticDataInfo,
          'selectedMatirialoption':null,
          'selectedsupplieroption':null,
          'selectedsupplierOfFarmsoption':null 
        });
        setformDataSearch({
            repositoryMaterialId: 0,
            supplierName: '',
            supplierOfFarmsName: '',
            Fromdate: '',
            ToDate: '',
            Allresults:false
        });
      }

    useEffect(() => {
        const fetchData = async () => {
                    
          axios.get(ApiURL +'RepositoryMaterials/GetAll',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              
              const Material_holder=[];
              data.data.materials.map((item)=>{
                Material_holder.push({
                  value:item.id,
                  label:item.name,
                  defaultPrice:item.defaultPrice})
                
              });
              
              const Supplier_holder=[];
              data.data.suppliers.map((item)=>{
                Supplier_holder.push({ value:item.supplierName,
                  label:item.supplierName})
                
              })
              const Buyer_holder=[];
              data.data.buyers.map((item)=>{
                Buyer_holder.push({ value:item.name,
                  label:item.name})
                
              })

              const SupplierOfFarms_holder=[];
              data.data.soFarms.map((item)=>{
                SupplierOfFarms_holder.push({ value:item.supplierName,
                  label:item.supplierName})            
              })
              
              staticinfo.setStaticDataInfo({
                ...staticinfo.StaticDataInfo,
                'Matirialoptions':Material_holder,
                'Buyeroptions': Buyer_holder,
                'supplieroptions': Supplier_holder,
                'supplierOfFarmsoptions':SupplierOfFarms_holder
              });

                         
            })
            .catch((err)=>{
              SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
              
              })
            
        };
       
        if (staticinfo.StaticDataInfo.supplierOfFarmsoptions.length===0) { 
            fetchData();
        }
      }, []);

    return (
        <>
       
            <div className='parent-Dmaterial'>
                <div className='child-Dmaterial'>
                <div className="mb-3" style={{textAlign:'center'}}><strong>بحث</strong></div>
                      {activator &&  <div className="mb-3">
                            <label className="form-label">
                            المادة:
                            </label>
                            <Select
                                id='Select_repositoryMaterialId_'
                                components={animatedComponents}
                                options={staticinfo.StaticDataInfo.Matirialoptions}
                                value={staticinfo.StaticDataInfo.selectedMatirialoption}
                                onChange={(selected)=> handleChange('Material',selected)} 
                                isClearable
                                isSearchable
                                isDisabled={formDataSearch.Allresults}
                                placeholder=" كل المواد..."                    
                            />      
                                 
                        </div>}

                        <div className="mb-3">
                            <label className="form-label">
                            المصدر:
                            </label>
                            <Select
                                id='Select_supplierName_'
                                components={animatedComponents}
                                options={staticinfo.StaticDataInfo.supplieroptions}
                                value={staticinfo.StaticDataInfo.selectedsupplieroption}
                                onChange={(selected)=> handleChange('supplier',selected)} 
                                isClearable
                                isSearchable
                                isDisabled={formDataSearch.Allresults}
                                placeholder="الكل..."                    
                            />              
                        </div> 

                       {supplierOfFarmsNameActive_ && <div className="mb-3">
                            <label className="form-label">
                            المورد:
                            </label>
                            <Select
                                id='Select_supplierName_'
                                components={animatedComponents}
                                options={staticinfo.StaticDataInfo.supplierOfFarmsoptions}
                                value={staticinfo.StaticDataInfo.selectedsupplierOfFarmsoption}
                                onChange={(selected)=> handleChange('supplierOfFarms',selected)} 
                                isClearable
                                isSearchable
                                isDisabled={formDataSearch.Allresults}
                                placeholder="الكل..."                    
                            />              
                        </div> }

                        <div className="mb-3">
                            <label className="form-label">
                                من
                            </label>
                            <input
                                className="form-control"
                                type="date"
                                name="Fromdate"
                                value={formDataSearch.Fromdate}
                                disabled={formDataSearch.Allresults}
                                onChange={(e)=>{handleChange('Fromdate',e.target.value)}}
                            />
                            
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                إلى
                            </label>
                            <input
                                className="form-control"
                                type="date"
                                name="ToDate"
                                value={formDataSearch.ToDate}
                                disabled={formDataSearch.Allresults}
                                onChange={(e)=>{handleChange('ToDate',e.target.value)}}
                            />
                            
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                جميع النتائج:  
                            </label>
                            <input
                                className="search-checkBox"
                                type="checkbox"
                                name="Allresults"
                                checked={formDataSearch.Allresults}
                                onChange={(e)=>{handleChange('Allresults',e.target.checked)}}
                            />
                            
                        </div>
                        <div className="mb-3">
                           <button className='SearchBlock-kh' onClick={()=>{RestValues();navigate(-1);}}>إلغاء</button>
                           <button className='SearchBlock-kh search-kh' onClick={()=>{navigate(-1);}}>بحث</button>
                        </div>
                </div>
            </div>
        </>
    );
}

export default Search;
