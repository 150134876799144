import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfokh } from '../../Contexts/UserContext';
import UserHeader from '../../Components/HeaderAndSidebar/UserHeader';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ApiURL } from '../../App';
import KhAlert from '../../Components/Alert/KhAlert';


const AddFuel = () => {

  //#region variable and states
  const userInfo_Now =useContext(UserInfokh);
  const [alert_,SetAlert]=useState({Activate:false,msg:''});
  const [flag,Setflag]=useState(false);

  const [Matirialoptions, setMatirialoptions] = useState([
    {
      value:1,
      label:'مازوت'
     },
     {
      value:2,
      label:'بنزين'
     },
     {
      value:3,
      label:'غاز'
     },
     {
      value:4,
      label:'كاز'
     },
  ]);
  const [suppliersoptions, setsuppliersoptions] = useState([]);

  const animatedComponents = makeAnimated();
  const [formData, setFormData] = useState({
    sourceName: '',      
    type:'',
    amount: '',
    priceOfOne: '',
    date: '',
    notes: ''
  });
 
  const handleCreateOptionsupplier = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setsuppliersoptions((prevOptions) => [...prevOptions, newOption]);
  
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    
  };
  //#endregion

  useEffect(() => {
    const fetchData = async () => {
                
      axios.get(ApiURL +'RepositoryMaterials/GetAll',{   
        headers:{
          'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
        }).then((data)=>{
         
          const Supplier_holder=[];
          data.data.suppliers.map((item)=>{
            Supplier_holder.push({ value:item.supplierName,
              label:item.supplierName})
            
          })
          setsuppliersoptions(Supplier_holder);
          
        })
        .catch((err)=>{
          SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
          })
    
    };

    fetchData();
  }, []);

 
  function RestValues(){
    Setflag(false);
    setFormData({
      sourceName: formData.sourceName,      
      type:formData.type,
      amount: '',
      priceOfOne: '',
      date: '',
      notes: ''
    });
  }

  const handleSubmit = async (e) => {
    Setflag(true);
    if(!(formData.type.length<1||formData.sourceName.length<2 || formData.amount<0.01 || formData.priceOfOne<0.01||formData.date.length<1))
    {
      try {
      const res = await axios.post(ApiURL+'Fuel/AddNewFuel', formData, {
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
           'Authorization': `bearer ${userInfo_Now.auth.token}`
        }
       
      });
      RestValues();
      SetAlert({Activate:true,msg:'تم ادخال البيانات بنجاح'});
      
    } catch (error) {      
      SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
     
    }
    }
  };
//#region OnWheel Prevent Change
const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}
//#endregion
  return (
    <div> 
      <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
      <UserHeader title='إضافة محروقات' SearchActivator={false}/>
      {/* <form className='form-addRepo' onSubmit={handleSubmit}> */}
      <div className='form-addRepo' >
            <div className="mb-3">
                <label className="form-label">
                النوع:
                </label>
                <Select
                    id='Select_repositoryMaterialId_'
                    components={animatedComponents}
                    options={Matirialoptions}
                    defaultValue={formData.type}
                    onChange={(selectedOption) => handleChange('type', selectedOption===null?0:selectedOption.label)}  
                    isClearable
                    isSearchable
                    placeholder="اختر نوع..."
                    
                  />
               {formData.type.length<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار نوع المحروقات</p>)}
            </div>

            <div className="mb-3">
                <label className="form-label">
                المصدر:
                </label>
                <CreatableSelect
                    id='Select_supplierName_'
                    components={animatedComponents}
                    options={suppliersoptions}
                    defaultValue={formData.sourceName}
                    onChange={(selectedOption) => handleChange('sourceName', selectedOption.value)}
                    onCreateOption={handleCreateOptionsupplier}
                    isSearchable
                    placeholder="اختر مصدر أو أضف مصدر جديد..."
                  />
               {formData.sourceName.length<2 &&flag&& ( <p className="validationReg"> *الرجاء اختيار مصدر</p>)}
            </div>
            
            <div className="mb-3"> 
              <label className="form-label">
                الكمية
              </label>
              <input
                className="form-control"
                type="number"
                name="amount"
                value={formData.amount}
                onChange={(e)=>{handleChange('amount',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.amount<0.01 &&flag&& ( <p className="validationReg"> *الرجاء تحديد الكمية</p>)}
            </div>

            
            <div className="mb-3"> 
              <label className="form-label">
              السعر الإفرادي
              </label>
              <input
                className="form-control"
                type="number"
                name="priceOfOne"
                value={formData.priceOfOne}
                onChange={(e)=>{handleChange('priceOfOne',e.target.value)}}
                onWheel={numberInputOnWheelPreventChange}
							/>
              {formData.priceOfOne<0.01 &&flag&& ( <p className="validationReg"> *الرجاء تحديد السعر</p>)}
            </div>
           
            <div className="mb-3">
              <label className="form-label">
                التاريخ
              </label>
              <input
                className="form-control"
                type="date"
                name="date"
                value={formData.date}
                onChange={(e)=>{handleChange('date',e.target.value)}}
              />
               {formData.date.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد التاريخ</p>)}
            </div>
            <div className="mb-3">
                <label className="form-label">
                ملاحظات
                </label>
                <textarea 
                type='text'
                className="form-control"
                name="notes" 
                value={formData.notes} 
                onChange={(e)=>{handleChange('notes',e.target.value)}}
                placeholder={"إضافة ملاحظات..."}               
                />
              
            </div>
       
        <button className='btn-Add' onClick={()=>{handleSubmit()}} >إضافة</button>
        
        </div>
      {/* </form> */}
    
    </div>
    
  );
};

export default AddFuel;
