import React from 'react';
import CategoryCredit from './CategoryCredit';

const SellectWindow = () => {
    const queryParameters = new URLSearchParams(window.location.search);  // to get CarId form url
    const isInput = queryParameters.get("isInput"); 
    
    return (
       <>
       <div className='parent-Dmaterial'>
       <div className='child-Dmaterial'>
        <div className='Listcategory-kh'>
       <h3>اختر فئة</h3>
            {isInput==='false' && <>
                    <CategoryCredit 
                    path='AddRepo?catId=4'
                    categoryName="طبالي" 
                    imageUrl={process.env.PUBLIC_URL+'/img/1.jpg'}
                />
                 <CategoryCredit 
                    path='AddRepo?catId=10'
                    categoryName="بلاستيك" 
                    imageUrl={process.env.PUBLIC_URL+'/img/11.jpg'}
                />
                <CategoryCredit 
                path='AddRepo?catId=3'
                    categoryName="كرستا" 
                    imageUrl={process.env.PUBLIC_URL+'/img/2.jpg'}
                />
                <CategoryCredit 
                path='AddDaily'
                    categoryName="الرزق اليومي" 
                    imageUrl={process.env.PUBLIC_URL+'/img/3.jpg'}
                />
                 <CategoryCredit 
                    path='AddFuel'
                    categoryName="محروقات" 
                    imageUrl={process.env.PUBLIC_URL+'/img/10.jpg'}
                />
                    <CategoryCredit 
                    path='AddCar'
                    categoryName="أجار سيارات " 
                    imageUrl={process.env.PUBLIC_URL+'/img/5.jpg'}
                />
        </>}
     
        {isInput==='true' && <> 
                <CategoryCredit 
                path='AddRefrigerator'
                categoryName="تحميل براد" 
                imageUrl={process.env.PUBLIC_URL+'/img/6.jpg'}
                />
                <CategoryCredit 
                 path='AddExternalEnvoice'
                categoryName="فواتير خارجية" 
                imageUrl={process.env.PUBLIC_URL+'/img/7.jpg'}
                />
                <CategoryCredit 
                 path='AddCoolingRoom'
                categoryName="غرف التبريد" 
                imageUrl={process.env.PUBLIC_URL+'/img/8.jpg'}
                />
                <CategoryCredit 
                 path='AddOtherSale'
                categoryName="مبيعات أخرى" 
                imageUrl={process.env.PUBLIC_URL+'/img/9.jpg'}
                />
             </>}
   </div>
   </div></div>
        


   </>
    );
}

export default SellectWindow;
