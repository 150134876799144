import { createContext, useState } from "react";

export const UserInfokh = createContext();

export default function UserInfoProvider({children}){

    // Get token from cookie
    const cookie_token = document.cookie
                  .split("; ")
                  .find((row) => row.startsWith('token'))
                  ?.split("=")[1];
    const cookie_Email = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith('email'))
                    ?.split("=")[1];
    const cookie_Password = document.cookie
                  .split("; ")
                  .find((row) => row.startsWith('password'))
                  ?.split("=")[1];
    const cookie_role = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith('role'))
                    ?.split("=")[1];              

    
    const [auth,setAuth]= useState({'token':cookie_token,'email':cookie_Email,'password':cookie_Password,'role':cookie_role});
    return (
        <UserInfokh.Provider value={{auth,setAuth}}>
            {children}
        </UserInfokh.Provider>
    )
}