
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import './App.css';
import MaterialPurchases from './Pages/Material purchases/MaterialPurchases';
import UserInfoProvider from './Contexts/UserContext';
import '../src/Components/NotFound/NotFoundCSS.css'
import AddRepository from './Pages/Material purchases/AddRepository';
import { ThemeProvider } from 'react-bootstrap';
import StaticInfoProvider from './Contexts/StaticDataContext';
import '../src/Components/CommonCss.css'
import DetailsRepository from './Pages/Material purchases/DetailsRepository';
import Search from './Components/Search/Search';
import FinancialEntitlements from './Pages/Financial Entitlements/FinancialEntitlements';
import DetailedDebts from './Pages/Financial Entitlements/DetailedDebts';
import FinancialPayments from './Pages/Financial Payments/FinancialPayments';
import AddPayment from './Pages/Financial Payments/AddPayment';
import AllFuel from './Pages/Fuel/AllFuel';
import DetailsFuel from './Pages/Fuel/DetailsFuel';
import AddFuel from './Pages/Fuel/AddFuel';
import AllCars from './Pages/Cars/AllCars';
import DetailsCar from './Pages/Cars/DetailsCar';
import AddCar from './Pages/Cars/AddCar';
import AllDaily from './Pages/Daily purchases/AllDaily';
import DetailsDaily from './Pages/Daily purchases/DetailsDaily';
import AddDaily from './Pages/Daily purchases/AddDaily';
import AllEmployees from './Pages/Employee/Employee/AllEmployees';
import AddEmployee from './Pages/Employee/Employee/AddEmployee';
import DailyCheckDetails from './Pages/Employee/Daily check/DailyCheckDetails';
import AddCheckEmpolyee from './Pages/Employee/Daily check/AddCheckEmpolyee';
import EditEmployee from './Pages/Employee/Employee/EditEmployee';
import MainPage from './Pages/MainPage/MainPage';
import AllRefrigerators from './Pages/Refrigerator/AllRefrigerators';
import DetailsRefrigerator from './Pages/Refrigerator/DetailsRefrigerator';
import AddRefrigerator from './Pages/Refrigerator/AddRefrigerator';
import SearchOut from './Components/Search For Out/SearchOut';
import AllExternalEnvoices from './Pages/External Envoices/AllExternalEnvoices';
import DetailsExternalEnvoices from './Pages/External Envoices/DetailsExternalEnvoices';
import AddExternalEnvoices from './Pages/External Envoices/AddExternalEnvoices';
import AllCoolingRooms from './Pages/Cooling Rooms/AllCoolingRooms';
import DetailsCoolingRoom from './Pages/Cooling Rooms/DetailsCoolingRoom';
import AddCoolingRoom from './Pages/Cooling Rooms/AddCoolingRoom';
import AddOtherSale from './Pages/Other Sales/AddOtherSale';
import AllOtherSales from './Pages/Other Sales/AllOtherSales';
import DetailsOtherSale from './Pages/Other Sales/DetailsOtherSale';
import SellectWindow from './Components/AddWindow/SellectWindow';
import GenerateBilling from './Pages/Billing/GenerateBilling';
import Login from './LogIn/Login';
import RequireAuth from './LogIn/RequireAuth';
import GenerateExpenseBill from './Pages/Billing/GenerateExpenseBill';
import StatementOfAccount from './Pages/Billing/StatementOfAccount';
import AllBoxesMain from './Box Section/Pages/AllBoxesMain';
import BoxesOfUser from './Box Section/Pages/BoxesOfUser';
import AddBoxes from './Box Section/Pages/AddBoxes';
import BoxOfUSerDetailed from './Box Section/Pages/BoxOfUSerDetailed';






//export const ApiURL='https://localhost:7132/';
//export const ApiURL='http://192.168.66.196:83/';
export const ApiURL='https://alarabiacom.ru/api/';
//export const ApiURL='http://ahmadibrahem-001-site2.htempurl.com/api/';
//
//
 
function App() {
  
 
  const queryParameters = new URLSearchParams(window.location.search);  // for login
  const fromresetpassword_ = queryParameters.get("fromresetpassword"); // for login
  
  
  return (
    <ThemeProvider dir="rtl">
    <UserInfoProvider>
      <StaticInfoProvider>
    <div className="App">
      
   

      <BrowserRouter>
       <Routes>
       
       {/* <Route path="/Login" element={<LogSign pagelink_='sign-in' fromresetpassword={fromresetpassword_}/>} /> */}
      
    
       <Route path='/login' element={<Login />} /> 
      <Route element={<RequireAuth />}>
            <Route path='/MP' element={<MaterialPurchases />} >
              <Route path='details' element={<DetailsRepository />} />
              <Route path='search' element={<Search />} />
              <Route path='SellectWindow' element={<SellectWindow />} />  
              
              
            </Route>
          
            <Route path='/debts' element={<FinancialEntitlements />} >
              <Route path='DetDebts' element={<DetailedDebts />} />
              
            </Route>
          
            <Route path='/Payments' element={<FinancialPayments />} >
              <Route path='AddPayment' element={<AddPayment />} />
              
            </Route>
          
            <Route path='/AllFuel' element={<AllFuel />} >
              <Route path='DetailsFuel' element={<DetailsFuel />} />  
              <Route path='search' element={<Search />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>    
            <Route path='/AllCars' element={<AllCars />} >
              <Route path='DetailsCar' element={<DetailsCar />} />  
              <Route path='search' element={<Search />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>   
            <Route path='/AllDaily' element={<AllDaily />} >
              <Route path='DetailsDaily' element={<DetailsDaily />} />  
              <Route path='search' element={<Search />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>   
            <Route path='/AllEmployees' element={<AllEmployees />} >
            <Route path='AddEmployee' element={<AddEmployee />} /> 
            <Route path='EditEmployee' element={<EditEmployee />} /> 
          
            </Route>   
            <Route path='/DetailsDailyCheck' element={<DailyCheckDetails />} >  
              <Route path='AddCheck' element={<AddCheckEmpolyee />} />
            
            </Route>
            <Route path='/AllRefrigerators' element={<AllRefrigerators />} >
              <Route path='DetailsRefrigerator' element={<DetailsRefrigerator />} />  
              <Route path='search' element={<SearchOut />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>   
            <Route path='/AllExternalEnvoices' element={<AllExternalEnvoices />} >
              <Route path='DetailsExternalEnvoices' element={<DetailsExternalEnvoices />} />  
              <Route path='search' element={<SearchOut />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>  
            <Route path='/AllCoolingRooms' element={<AllCoolingRooms />} >
              <Route path='DetailsCoolingRoom' element={<DetailsCoolingRoom />} />  
              <Route path='search' element={<SearchOut />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>  
            <Route path='/AllOtherSales' element={<AllOtherSales />} >
              <Route path='DetailsOtherSale' element={<DetailsOtherSale />} />  
              <Route path='search' element={<SearchOut />} /> 
              <Route path='SellectWindow' element={<SellectWindow />} />  
            </Route>  


            <Route path='/AllBoxesMain' element={<AllBoxesMain />} / >
            <Route path='/BoxesOfUser' element={<BoxesOfUser />}  >
              <Route path='BoxOfUSerDetailed' element={<BoxOfUSerDetailed />} />
            </Route>
            <Route path='/AddBoxesEntering' element={<AddBoxes IsEnteringNotExiting={true}/>} / >
            <Route path='/AddBoxesExiting' element={<AddBoxes IsEnteringNotExiting={false}/>} / >
             
            
            
            <Route path='AddRepo' element={<AddRepository />}></Route>
            <Route path='AddFuel' element={<AddFuel />} />  
            <Route path='AddCar' element={<AddCar />} />  
            <Route path='AddDaily' element={<AddDaily />} />  
            <Route path='AddRefrigerator' element={<AddRefrigerator/>} />  
            <Route path='AddExternalEnvoice' element={<AddExternalEnvoices/>} />  
            <Route path='AddCoolingRoom' element={<AddCoolingRoom/>} />  
            <Route path='AddOtherSale' element={<AddOtherSale/>} />  
          
            <Route path='/' element={<MainPage />} >  
            <Route path='GenerateBilling' element={<GenerateBilling />} /> 
            <Route path='GenerateExpenseBill' element={<GenerateExpenseBill />} /> 
            <Route path='StatementOfAccount' element={<StatementOfAccount />} /> 
            
            </Route>
           
        </Route>

      </Routes>
    
    </BrowserRouter>
     
    
    
    </div>
    </StaticInfoProvider>
    </UserInfoProvider>
    </ThemeProvider>
  );
}

export default App;

