import React from 'react';
import { NumericFormat } from 'react-number-format';

const DataList = ({data}) => {
    const items = [
        { label: data[0].name, value: data[0].value },
        { label: data[1].name, value: -data[1].value},
        { label: data[2].name, value: data[2].value },
      ];
    
      return (
        <div className="data-list">
          {items.map((item, index) => (
            <div key={index} className="data-item">
               
              <span>{item.label}:</span> 
              <span style={item.value<0?{color:'red'}:{color:'green'}} ><NumericFormat value={item.value} displayType={'text'} thousandSeparator={true} />  ل.س</span>
             
            </div>
          ))}
        </div>
      );
    }

export default DataList;
