import { useContext, useEffect, useState } from "react";
import { UserInfokh } from "../../../Contexts/UserContext";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiURL } from "../../../App";
import { NumericFormat } from "react-number-format";
import NotFound from "../../../Components/NotFound/NotFound";
import UserHeader from "../../../Components/HeaderAndSidebar/UserHeader";
import Skeleton_kh from "../../../Components/Skeleton/Skeleton_kh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmation from "../../../Components/Delete Confirmation component/DeleteConfirmation";
import KhAlert from "../../../Components/Alert/KhAlert";


const AllEmployees = () => {

  //#region variables and states
    const userInfo_Now =useContext(UserInfokh);
    const [Alldata, setAlldata] = useState([]);
    const [loading,setloadnig]=useState(true);
    const [dataAsdate, setdataAsdate] = useState([]);
    const [SumAll, setSumAll] = useState(0);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});
    
    const [value, setValue] = useState(new Date());
    const [SearchFlage,setSearchFlage]=useState(false);
    const navigate =useNavigate();
    const [classtd,setclasstd]=useState(['hidden-info']);
    const [classbtn,setclassbtn]=useState(['show-button show-button-down'])

    //#endregion

    useEffect(() => {
        const fetchData = async () => {
            setloadnig(true);
          
          axios.get(ApiURL+'Employee/GetEmployees',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              setAlldata(data.data);
              setloadnig(false);
            
            })
            .catch((err)=>{
                setloadnig(false);
            //   setِ_ِAlert_msg(err);
            //   setِAlert(true); 
                       
              })
        
        };
    
        fetchData();
      }, []);

      useEffect(() => {
       
        let data_temp = [];
        let data_temp_forSearch = [];
        const newClasstd = [];
        const newclassbtn = [];
        setSumAll(0);
        let sum_=0;
       
            setSearchFlage(false);
            Alldata.forEach((item) => {
              const itemCopy = { ...item }; // Create a copy of the item object
                                
              sum_ +=itemCopy.remainder;
              data_temp.push(itemCopy);
              newClasstd.push('hidden-info');
              newclassbtn.push('show-button show-button-down');
            });
          
        setSumAll(sum_);
        setdataAsdate(data_temp);
        setclasstd(newClasstd);
        setclassbtn(newclassbtn);

      }, [value, Alldata]);
      

      function toggleInfo(id)
      {
       
        const newClasstd = [...classtd];
        const newclassbtn = [...classbtn];
        
        if(newClasstd[id]==='show-info')
          {
            newClasstd[id]= 'hidden-info';
            newclassbtn[id]='show-button show-button-down'
          }
        else
        {
          newClasstd[id]= 'show-info';
          newclassbtn[id]='show-button show-button-up'
        }
        setclasstd(newClasstd);
        setclassbtn(newclassbtn);
      }

       //#region delete element

        
       function handleDeleteClick(id) {
        setDeleteConfirmation({'active':true,'id':id});
    }
 
    const handleClose = () => {
        setDeleteConfirmation({'active':false,'id':0});
    };
  
    function handleConfirm(id) {
        // Perform the delete action here
        DeleteHundel(id);
        setDeleteConfirmation({'active':false,'id':0});
    }
   

    //#endregion


    async function DeleteHundel(id)
    {
      setloadnig(true);  
      const Func_Param=ApiURL+`Employee/DeleteEmployee?id=${id}`;
      try{
       
        const res=await axios.options(Func_Param,{            
          headers:{
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${userInfo_Now.auth.token}`
          }
      });
   console.log(res.data);
        if(res.data)
          {
            navigate(0);
           
          }
          else{
            SetAlert({Activate:true,msg:'قم بحذف جدول التفقد الخاص بالورشة قبل حذفها!'});
          }
      }
      catch(error_)
      {
       
        SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
        
      }

      setloadnig(false); 
    } 

    return (
    <>
       <Outlet />
       <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
         <UserHeader title='جميع الورشات' SearchActivator={false} Active={7} />
         {!loading && dataAsdate.length===0 && <NotFound />}
         {dataAsdate.length>0 && <div className='main-div-kh'>
            <div className='MaterialPurchases-Sum' ><h6><b>مجموع المبالغ المتبقية: </b><span style={SumAll>0?{color:'red'}:{color:'green'}} ><NumericFormat value={SumAll} displayType={'text'} thousandSeparator={true} />  ل.س</span></h6></div>
            <table className='table-res'>
     
      <tbody>
        {Alldata.map((item,index) => (
          <tr key={item.id} className='tr-res'>
            <td className='show-info' onClick={()=>{navigate('/DetailsDailyCheck?Id='+item.id+'&workshopName='+item.workshopName)}}>
              <span >اسم الورشة:</span> {item.workshopName}
            </td>
            <td className={classtd[index]}>
              <span >سعر الساعة للشباب:</span> <span><NumericFormat value={item.normHWageM} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span >سعر الساعة للبنات:</span> <span><NumericFormat value={item.normHWageG} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span >سعر الساعة الإضافي:</span> <span><NumericFormat value={item.additionalWorkingHourWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span > الراتب الإبتدائي :</span> <span><NumericFormat value={item.totalWage} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span> مجموع أجار السرافيس:</span> <span><NumericFormat value={item.totalRewards} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span>مجموع الحسميات:</span> <span><NumericFormat value={item.totalDiscount} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            
            <td className={classtd[index]}>
              <span> المبلغ المطلوب الكلي :</span> <span><NumericFormat value={item.totalWageAfterDiscount} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span > الدفعات :</span> <span><NumericFormat value={item.payments} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className='show-info'>
              <span > المبلغ المتبقي :</span> <span><NumericFormat value={item.remainder} displayType={'text'} thousandSeparator={true} /> ل.س </span>
            </td>
            <td className={classtd[index]}>
              <span>  الملاحظات:</span> {item.notes}              
            </td>
            <td className={classtd[index] }>
             <span>  </span> 
             <span className="span-btn-group"> <span className='Daily-Edit' onClick={()=>{navigate('/allEmployees/EditEmployee?Id='+item.id)}}><FontAwesomeIcon icon={faPenToSquare} /> تعديل</span>
             <span className='Daily-delete' onClick={()=>{handleDeleteClick(item.id)}}><FontAwesomeIcon icon={faTrashCan} /> حذف</span></span>
           </td>
            <td className={classbtn[index]} style={{padding:'0'}} onClick={()=>toggleInfo(index)}></td>
          </tr>
        ))}
      </tbody>
    </table>

        </div>
}
        
        
        {loading && <Skeleton_kh />}   
        <button className='Float-button' onClick={()=>{navigate('/AllEmployees/AddEmployee')}}>+</button>
        <DeleteConfirmation
        show={showDeleteConfirmation}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
   
    </>
        
    );
}

export default AllEmployees;
