import React from 'react';


const NotFound = () => {
    return (
       <div className='NotFound-kh'>
            <h4>لايوجد نتائج</h4>
       </div>
    );
}

export default NotFound;
