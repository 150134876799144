import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Skeleton_kh = () => {
    const array_=[1,2,3,4,5,6,7,8];
    return (
        <>
        {array_.map((item)=>
            <div key={item} className='Transaction-kh'>          
        <div className="parent-kh">
        <div> <Skeleton height='8px' width='80px' /></div>
        <div> <Skeleton height='8px' width='120px' /></div>
        <div> <Skeleton height='8px' width='160px' /></div>
        <div> <Skeleton height='8px' width='90px' /></div>
        </div>       
    </div>
        )}
            
        </>
    );
}

export default Skeleton_kh;
