import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const GroupsR = ({Matirialoptions,setFormData,formData,subformData,indexOfsubFdata,flag}) => {

    const animatedComponents = makeAnimated();
    const [local_form,setlocal_form]=useState({
        repositoryMaterialId: null,
        totalBoxes: '',
        balanceCardWeight: '',
        emptyBoxesWeight: 2,
        salesPriceOfUnit: '',
    });
  
       
    const handleChange = (name, value) => {

        var helper_subformData= JSON.parse(JSON.stringify(formData));

       
        helper_subformData.details[indexOfsubFdata][name]=value;

        setFormData(helper_subformData);
        setlocal_form({
            ...local_form,
            [name]:[value]
        })
        
      };

        //#region OnWheel Prevent Change
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
      
        // Prevent the page/container scrolling
        e.stopPropagation()
      
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
      //#endregion

    return (
        <div className='R-subgroup'>
            <div>
             <div className="mb-3">
                  <label className="form-label">
                  المادة:
                  </label>
                  <Select
                      id='Select_repositoryMaterialId_'
                      components={animatedComponents}
                      options={Matirialoptions}
                      defaultValue={local_form.sellectMatirial}
                      onChange={(selectedOption) => handleChange('repositoryMaterialId', selectedOption===null?0:selectedOption.value)}  
                      isClearable
                      isSearchable
                      placeholder="اختر فاكهة..."
                      
                    />
                 {subformData.repositoryMaterialId<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار فاكهة</p>)}
              </div>
  
              <div className="mb-3"> 
                <label className="form-label">
                  عدد الطبالي
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="totalBoxes"
                  value={local_form.totalBoxes}
                  onChange={(e)=>{handleChange('totalBoxes',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                              />
                {subformData.totalBoxes<0.01 &&flag&& ( <p className="validationReg"> *الرجاء إدخال عدد الطبالي </p>)}
              </div>
              <div className="mb-3"> 
                <label className="form-label">
                  الوزن القائم
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="balanceCardWeight"
                  value={local_form.balanceCardWeight}
                  onChange={(e)=>{handleChange('balanceCardWeight',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                              />
                {subformData.balanceCardWeight<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال الوزن القائم  </p>)}
              </div>
              <div className="mb-3"> 
                <label className="form-label">
                  وزن الطبلية
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="emptyBoxesWeight"
                  value={local_form.emptyBoxesWeight}
                  onChange={(e)=>{handleChange('emptyBoxesWeight',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                />
                {subformData.emptyBoxesWeight<1 &&flag&& ( <p className="validationReg"> *الرجاء إدخال وزن الطبلية   </p>)}
              </div>
  
             
              <div className="mb-3"> 
                <label className="form-label">
                سعر الكيلو الواحد 
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="salesPriceOfUnit"
                  value={local_form.salesPriceOfUnit}
                  onChange={(e)=>{handleChange('salesPriceOfUnit',e.target.value)}}
                  onWheel={numberInputOnWheelPreventChange}
                  />
                {subformData.salesPriceOfUnit.length<1 &&flag&& ( <p className="validationReg"> *الرجاء تحديد السعر</p>)}
              </div>
              </div>
        </div>
    );
}

export default GroupsR;
