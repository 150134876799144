import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

const Transaction = (props) => {
    
    const navigate=useNavigate();
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    };
    
    return (
        <div className='Transaction-kh'>
          
             <div className="parent-kh" onClick={()=>{navigate('details?Id='+props.props.id)}}>
                <div> <h5><span style={props.props.buyPriceOfAll>0?{color:'red'}:{color:'green'}} ><NumericFormat value={props.props.buyPriceOfAll} displayType={'text'} thousandSeparator={true} />  ل.س</span> </h5></div>
                <div> <h4>{props.props.name} | {props.props.supplierName}</h4></div>
                <div> <h5>{formatDate(props.props.date)}</h5></div>
                <div> <h5> العدد: {props.props.amount}</h5></div>
            </div>
            
           
        </div>
    );
}

export default Transaction;
