import { faArrowUpWideShort, faCalendar, faCalendarDays, faCalendarPlus, faComments, faHandshake, faMoneyBill1Wave, faRecycle, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserInfokh } from '../../Contexts/UserContext';
import axios from 'axios';
import { ApiURL } from '../../App';
import Skeleton_kh from '../../Components/Skeleton/Skeleton_kh';
import Skeleton from 'react-loading-skeleton';
import KhAlert from '../../Components/Alert/KhAlert';
import DeleteConfirmation from '../../Components/Delete Confirmation component/DeleteConfirmation';
import { NumericFormat } from 'react-number-format';

const DetailsRepository = () => {

    const { setAlldata } = useOutletContext();

    const queryParameters = new URLSearchParams(window.location.search);  // to get RepositoryId form url
    const RepositoryId = queryParameters.get("Id"); 

    const navigate=useNavigate();

    const [data, setData] = useState({});
    const [loading,setloadnig]=useState(true);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});

    const userInfo_Now =useContext(UserInfokh);

    const [showDeleteConfirmation, setDeleteConfirmation] = useState({'active':false,'id':0});

      function handleDeleteClick(id) {
        setDeleteConfirmation({'active':true,'id':id});
      }
      const handleClose = () => {
        setDeleteConfirmation({'active':false,'id':0});
      };

      function handleConfirm() {
        // Perform the delete action here
        DeleteHundel();
        setDeleteConfirmation({'active':false,'id':0});
      }

    useEffect(()=>{

        const fetchData = async () => {
            setloadnig(true);
            try {
            const res=  await axios.get(ApiURL+'RepositoryInOut/GetRepositoryById?id='+RepositoryId,{   
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
              });
              setData(res.data);
                setloadnig(false);     
              }
              catch(err)
              {
                SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
                setloadnig(false);   
             
            }
          
          };
         
            fetchData();
            window.history.replaceState(null,'','/');
            
          
        },[]);
    
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
        
            return `${day}/${month}/${year}`;
        };

        
        async function DeleteHundel()
        {
          setloadnig(true);  
          const Func_Param=ApiURL+`RepositoryInOut/DeleteRepository?Id=${data.id}`;
          try{
           
            const res=await axios.options(Func_Param,{            
              headers:{
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${userInfo_Now.auth.token}`
              }
          });
       
            if(res.data)
              {
                // Refresh the page
                setAlldata((prevData) => prevData.filter(item => item.id !== data.id));
                navigate('/MP');
               
              }
              else{
                SetAlert({Activate:true,msg:'يوجد خطأ بالبيانات'});
              }
          }
          catch(error_)
          {
           
            SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
            
          }
    
          setloadnig(false); 
        } 

    return (
        <>
        <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
            <div className='parent-Dmaterial'>
                <div className='child-Dmaterial'>
                {!loading && <><span className='close-kh' onClick={()=>{navigate('/MP')}}> X </span>
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faCalendarDays} /> التاريخ:  </span> {formatDate(data.date)} </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faRecycle} /> المادة: </span> {data.name}</div> 
                   <div className='Details-kh'> <span> <FontAwesomeIcon icon={faHandshake} /> المصدر: </span>  {data.supplierName} </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faArrowUpWideShort} /> الكمية: </span>  {data.amount} </div> 
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faMoneyBill1Wave} /> سعر الشراء الافرادي: </span>  {data.buyPriceOfUnit} ل.س</div>  
                   <div className='Details-kh'> <span><FontAwesomeIcon icon={faSackDollar} /> السعر الكلي: </span><NumericFormat value={data.buyPriceOfAll} displayType={'text'} thousandSeparator={true} />   ل.س</div>  
                  {data.notes!==undefined && <div className='Details-kh'> <span><FontAwesomeIcon icon={faComments} /> ملاحظات: </span><div className='Note-kh'> {data.notes} </div>  </div>  } 
                   <div className='Details-kh'> <button className='btn-Add btn-delete' onClick={()=>{handleDeleteClick()}}>حذف</button> </div>  
                   </> }
                   {loading && <>
                    <div className='Details-kh'> <Skeleton height='10px' width='200px' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='180px' /></div>
                    <div className='Details-kh'> <Skeleton height='10px' width='150px' /></div>
                    <div className='Details-kh'> <Skeleton height='150px'  /></div>
                   </>}  
                </div>
            
                <DeleteConfirmation
                  show={showDeleteConfirmation}
                  handleClose={handleClose}
                  handleConfirm={handleConfirm}
                />
            </div>
             
        </>
    );
}

export default DetailsRepository;
